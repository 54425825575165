import {useEffect, useState, useCallback} from "react";
import {debounce} from "@/utils/eventOptimization";

/**@param {number} isCustomWidth 커스텀할 반응형 숫자 입력하여 사용 (default: 1800)
 * @return {{ isMobile: boolean, isTablet: boolean, isDesktop: boolean, isCustomWidth: boolean}} */
export default function useResponsive(isCustomWidth = 1800) {
  // 대응 사이즈 Constant Value
  // 상수로 지정하여 기준이 바뀔 경우 SIZE 상수 값 변경만으로 관리
  const SIZE = {mobile: 640, tablet: 1280};

  // default state를 지정하여 즉시 반영
  const [screenType, setScreenType] = useState({
    isMobile: window.innerWidth <= SIZE.mobile,
    isTablet: window.innerWidth >= SIZE.mobile && window.innerWidth <= SIZE.tablet,
    isDesktop: window.innerWidth >= SIZE.tablet,
    // 커스텀 가능한 @media max-width
    isCustomWidth: window.innerWidth <= isCustomWidth,
  });

  const handleResize = useCallback(
    debounce(() => {
      const width = window.innerWidth;
      setScreenType({
        isMobile: width <= SIZE.mobile,
        isTablet: width >= SIZE.mobile && width <= SIZE.tablet,
        isDesktop: width >= SIZE.tablet,
        isCustomWidth: width <= isCustomWidth,
      });
    }, [300]),
    [isCustomWidth],
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // * 디바운싱 적용확인
  // useEffect(() => {
  //   console.log("isMobile", screenType.isMobile);
  //   console.log("isTablet", screenType.isTablet);
  //   console.log("isDeskto", screenType.isDeskto);
  //   console.log("isCustomWidth", screenType.isCustomWidth);
  // }, [screenType.isMobile, screenType.isTablet, screenType.isDeskto, screenType.isCustomWidth]);

  return screenType;
}

//  반응형 작업

/*
 * import useResponsive from "@/hooks/useResponsive.jsx"
 *
 *
 * const component = () => {
 * const {isMobile , isTablet ,isDesktop } = useResponsive();
 *
 *  return(
 *   {isMobile  && <p>모바일 랜더</p>}
 *   {isTablet  && <p>테블릿  랜더</p>}
 *   {isDesktop  && <p>pc 랜더</p>}
 *  )
 * }
 *
 * export default component;
 *
 */
