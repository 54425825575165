import axios from "axios";
import {setInterceptors} from "./common/interceptor";

/**
 * @description token이 필요없는 auth ( login )
 */
function createInstanceGuestAuth() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL_AUTH,
  });
}

/**
 * @description token이 필요없는 nodejs
 */
function createInstanceGuestNodejs() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL_NODE,
  });
}

/**
 * @description token이 필요없는 java
 */
function createInstanceGuestJava(url) {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_JAVA}${url}`,
  });
}

/**
 * @description auth + token instance 회원 관련 요청
 * @param url
 */
function createInstanceAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_AUTH}${url}`,
  });
  return setInterceptors(instance);
}

/**
 * @description nodejs + token instance nodejs 관련 R 요청
 * @param url
 */
function createInstanceNodejs(url) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_NODE}${url}`,
  });
  return setInterceptors(instance);
}

/**
 * @description user + token instance 사용자화면 java CUD 요청
 * @param url
 */
function createInstanceJava(url) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_JAVA}${url}`,
  });
  return setInterceptors(instance);
}

/**
 * @description java elastic 통합 검색
 * @param url
 */
function createInstanceJavaElastic(url) {
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_ELASTIC}${url}`,
  });
}

// port 15000
export const guestAuth = createInstanceGuestAuth();
export const auth = createInstanceAuth("auth");

// port 4000 node js
export const guestNodeApi = createInstanceGuestNodejs(); //login 없이 nodejs 조회하는 api
// 4000/api/com
export const ComReadApi = createInstanceNodejs("api/com");
// 4000/api/lms
export const LmsReadApi = createInstanceNodejs("api/lms");
// 4000/api/bmc
export const BmcReadApi = createInstanceNodejs("api/bmc");

//port 14000 java
export const guestJavaApi = createInstanceGuestJava();
// baseUrl: 14000/api/com
export const ComCUDApi = createInstanceJava("api/com");
// baseUrl: 14000/api/lms
export const LmsCUDApi = createInstanceJava("api/lms");
// baseUrl: 14000/api/bmc
export const BmcReadJavaApi = createInstanceJava("api/bmc");
// baseUrl: 14000/api/
export const BmcCUDApi = createInstanceJava("api/bmc");
// baseUrl: 9200/movieData/_search
export const searchApi = createInstanceJavaElastic("");
