import {useCallback, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import MainSearchInput from "./MainSearch/MainSearchInput";
import closeButton from "@/assets/svg/close_button.svg";
import {main_search_mock as data} from "@/mock/main-search-mock.js";
import {toast} from "react-toastify";

const MainSearch = ({isSearchVisible, setIsSearchVisible}) => {
  const overlay = useRef(null);

  const navigate = useNavigate();

  const onDismiss = useCallback(() => {
    setIsSearchVisible(false);
  }, [setIsSearchVisible]);

  const onClick = useCallback(
    (e) => {
      if (e.target === overlay.current) onDismiss();
    },
    [onDismiss, overlay],
  );

  const handleToggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleSubmit = useCallback((term) => {
    if (term?.trim() === "") return toast.warn("검색어를 입력해주세요");
    if (term?.trim().length < 2) return toast.warn("두 글자 이상 입력해주세요");
    navigate(`/elastic?search=${term}`, {replace: true});
    setIsSearchVisible(false);
  }, []);

  return (
    <div className={`overlay ${isSearchVisible ? "open" : ""}`} ref={overlay} onClick={onClick}>
      <div className="mainSearchContainer">
        <h1 className="mainSearchLogo">창업교육 혁신 선도대학 SCOUT</h1>
        <div className="mainSearch">
          <h2 className="mainSearchTitle">통합검색</h2>
          <MainSearchInput parentsubmit={handleSubmit} isSearchVisible={isSearchVisible} />

          <div className="popularKeywordsList">
            <span>많이 찾는 검색어</span>
            <ul className="popularKewordsItems">
              {data.slice(0, 5).map((item) => (
                <li key={item.id}>
                  {/* 각 검색어를 클릭하면 해당 페이지로 이동 - 수정해야함 */}
                  <Link to={item.params} onClick={handleToggleSearch}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button className="searchCloseButton" onClick={handleToggleSearch}>
          닫기
          <img src={closeButton} />
        </button>
      </div>
    </div>
  );
};

export default MainSearch;
