import {createSlice} from "@reduxjs/toolkit";

export const educationSlice = createSlice({
  name: "education",
  initialState: {
    curriculumChasiData: {},
  },
  reducers: {
    setCurriculumChasiData: (state, action) => {
      state.curriculumChasiData = action.payload;
    },
  },
});

// 생성된 액션 생성자 함수를 내보냅니다.
export const {setCurriculumChasiData} = educationSlice.actions;

// 리듀서를 내보냅니다.
export default educationSlice.reducer;
