import {createSlice} from "@reduxjs/toolkit";
import {getTokenCookies, removeTokenCookies, setTokenCookies} from "@/utils/cookies";
import {parseJWT} from "@/utils/JWT";

// Redux에서 관리할 초기 상태
const initialState = {
  userInfo: {},
  accessToken: "",
  memberInfo: {},
};
// ! 이 부분 보기 -> 변경해서 사용
const auth = createSlice({
  // ! 함수에 auth와
  name: "auth", // ! name : auth  --> 이름 동일하게 지어줘야됨
  initialState,
  reducers: {
    setToken: (state, {payload: accessToken}) => {
      state.accessToken = accessToken;
    },
    // action 생성
    login: (state, {payload}) => {
      const {
        data: {accessToken, refreshToken},
      } = payload;
      setTokenCookies({refreshToken, accessToken});
      const newToken = getTokenCookies().accessToken;
      state.userInfo = {...parseJWT(newToken)};
      state.accessToken = accessToken;
    },
    logout: (state) => {
      // 쿠키를 통해 중복 프로세스 방지
      const {refreshToken, accessToken, userInfo} = getTokenCookies();
      if (!refreshToken || !accessToken || !userInfo) return;

      // 토큰이 소실되었을 때
      window.location.href = "/auth/login"; // 로그인 페이지로 이동
      state.userInfo = initialState.userInfo;
      state.accessToken = "";
      removeTokenCookies();
      alert("로그아웃 되었습니다.");
    },
    setMemberInfo: (state, {payload}) => {
      state.memberInfo = payload;
    },
  },
});

export const {setToken, login, logout, setMemberInfo} = auth.actions;

// selector
export const selectAuthState = (state) => state.auth;

// selecter 사용법 - useSelector ,  selectAuthState  => import
// const userInfo = useSelector(selectAuthState);

// api 요청 시
// const reqeustData = {
//   cNo: userInfo.cNo,
//   cName: userInfo.cName,
// };

export default auth;
