import React from "react";
import {useNavigate} from "react-router-dom";
import image from "@/assets/svg/main_img_03.svg";

const EducationPlan = () => {
  const navigate = useNavigate();
  const handleShorcut = () => navigate("/toolkit/bmcAbout");

  return (
    <div className="plan">
      <div className="educatioInfoContainer">
        <div className="educationContentsBox">
          <img
            className="educationInfoImage"
            src={image}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          />
          <div
            className="educationInfoBox"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <span className="number">03</span>
            <h3 className="title">비즈니스 모델 캔버스 작성</h3>
            <p className="description">
              사업계획서를 통한 아이템 검토 후 비즈니스 모델 캔버스로,
              <br />
              사업모형을 개발하고 문서화하여 팀원들과 공유가 가능합니다.
            </p>
            <span className="shorcutButton" onClick={handleShorcut}>
              바로가기
              <button>
                <img src={require("@/assets/images/icon_arrow_right_blue.png")} />
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationPlan;
