// import React from "react";
import styled from "styled-components";

const SVG = styled.svg`
  width: 54px;
  height: 54px;
`;

const Circle = styled.circle`
  fill: ${(props) => props.color};

  ${SVG}:hover & {
    fill: ${(props) => props.$hoverColor};
    transition: all 0.2s ease-in-out;
  }
`;

export default function MainPageButton({color, hoverColor, position}) {
  return (
    <SVG viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Circle cx="27" cy="27" r="27" color={color} $hoverColor={hoverColor} />
      <path
        d={
          position === "left"
            ? "M30.4616 17.3077L20.7693 27L30.4616 36.6924"
            : "M22.1539 36.6923L31.8462 27L22.1539 17.3076"
        }
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </SVG>
  );
}
