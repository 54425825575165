import React from "react";
import {useNavigate} from "react-router-dom";

const MainUniversityAbout = () => {
  const navigate = useNavigate();
  const handleShorcut = () => navigate("/about/consortium");

  return (
    <section className="universityAboutContainer">
      <div className="universityContent">
        <h2 className="universityTextTitle">함께 협력하는 참여 대학</h2>
        <p className="universityText">
          통합 플랫폼을 통해 지역 기업 및 기관의 적극적인 참여를 촉진하여 효과적인 지역 협력 창업
          교육 생태계 기반을 강화하고 창업교육 및 지원 분야 전문인력 교류와 인프라 공유를 통한
          창업교육 관련 데이터베이스를 구축합니다
        </p>
        <button className="unicersityAboutButton" onClick={handleShorcut}>
          <span className="buttonName">대학 소개페이지 바로가기</span>
          <img
            className="buttonArrowIcon"
            src={require("@/assets/images/icon_arrow_right_white.png")}
          />
        </button>
      </div>
    </section>
  );
};

export default MainUniversityAbout;
