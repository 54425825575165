import React from "react";

export const HEADER_LIST = [
  {
    title: "사업소개",
    to: "/about/scout",
    list: [
      {title: "SCOUT 사업 소개", to: "/about/scout"},
      {title: "권역별 컨소시엄 소개", to: "/about/consortium"},
    ],
  },

  {
    title: "창업교육 프로그램",
    to: "/education/course",
    list: [
      {title: "프로그램 소개", to: "/education/course"},
      {title: "프로그램 신청", to: "/education/list"},
      {title: "멘토링", to: "/board/mentoring?p=1&myP=1"},
    ],
  },

  {
    title: "창업교육 툴킷",
    to: "/toolkit/bmcAbout",
    list: [
      {
        title: <p>창업교육 툴킷 소개</p>,
        to: "/toolkit/bmcAbout",
      },
      {
        title: (
          <p>
            BMC 작성
            <span>(비즈니스 모델 캔버스)</span>
          </p>
        ),
        to: "/toolkit/bmc/list",
      },
    ],
  },

  {
    title: "지역 문제해결 허브",
    to: "/local/map",
    list: [{title: "로컬실록지리지", to: "/local/map"}],
  },

  {
    title: "창업교육 콘텐츠",
    to: "/board/eduContents/researchRef?p=1",
    list: [
      {title: "수업 · 연구자료", to: "/board/eduContents/researchRef?p=1"},
      {title: "동영상 자료", to: "/board/eduContents/videoRef?p=1"},
      {title: "갤러리", to: "/board/eduContents/gallery?p=1"},
    ],
  },

  {
    title: "공지사항",
    to: "/board/notice?p=1",
    list: [
      {title: "공지사항", to: "/board/notice?p=1"},
      {title: "FAQ", to: "/board/FAQ?p=1"},
      {title: "1:1문의", to: "/board/inquiry?p=1"},
    ],
  },
];
