import store from "@/store/main";
import {logout} from "@/store/modules/authSlice";
import {getTokenCookies} from "@/utils/cookies";

export const scrollTop = () => window.scrollTo(0, 0);

// 쿠키가 하나라도 없다면 로그아웃 처리
export const ifNonCookiesLogout = () => {
  const cookies = getTokenCookies();
  if (!cookies.accessToken || !cookies.refreshToken || !cookies.userInfo) {
    store.dispatch(logout());
  }
}

/**@desc textarea ref 요소를 넣으면 줄바꿈 시 자동으로 높이 조절
 * @param {MutableRefObject<any>} textareaRef
 * */
export const resizeTextarea = (textareaRef) => {
  if (textareaRef.current) {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${ textareaRef.current.scrollHeight }px`;
  }
}

/**@desc 다중구조(중첩구조)를 가진 객체(배열포함)을 완벽하게 깊은 복사할 때 사용*/
export const jsonDeepCopy = (target) => {
  return JSON.parse(JSON.stringify(target));
}

// padStart 2자리까지 앞에 0 채우는 함수
export const padFillZero = (target) => String(target).padStart(2, "0");