import {ReactComponent as Close_white} from "@/assets/svg/close_white.svg";

// * TopSubTitle 대신 사용 - 모달 헤더 부분
const ModalHedaer = ({modalTitle = "모달 제목", setShowModal, closeClickHandler}) => {
  return (
    <div className="ModalHedaer">
      <div className="title">{modalTitle}</div>
      <button
        className="btn_close"
        onClick={() => {
          setShowModal(false);
          if (closeClickHandler) {
            closeClickHandler();
          }
        }}
      >
        <Close_white width={"16px"} height={"16px"} fill="#fff" />
      </button>
    </div>
  );
};

export default ModalHedaer;
