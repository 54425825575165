import {Link} from "react-router-dom";

const HeaderAuth = ({
  isMobile = false,
  isTablet = false,
  isLogin = false,
  handleLogout,
  setIsMenuOpen,
}) => {
  return (
    <div className={[isMobile || isTablet ? "mobile_auth_box" : "auth_box"].join(" ")}>
      {!isLogin ? (
        <ul>
          <li>
            <Link to="auth/login" onClick={() => setIsMenuOpen()}>
              로그인
            </Link>
          </li>
          <li>
            <Link to="auth/signup" onClick={() => setIsMenuOpen()}>
              회원가입
            </Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <a
              className="cursor"
              onClick={(e) => {
                setIsMenuOpen();
                handleLogout(e);
              }}
            >
              로그아웃
            </a>
          </li>
          <li>
            <Link to="auth/mypage" onClick={() => setIsMenuOpen()}>
              마이페이지
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default HeaderAuth;
