import {createSlice} from "@reduxjs/toolkit";

// BMC 고객
const initialState = {
  customerList: [
    {
      id: 1,
      c_no: 22,
      customer: "고객 1",
      call_Hypo: null,
      color: "FF293D",
    },
    {
      id: 2,
      c_no: 33,
      customer: "고객 2",
      call_Hypo: null,
      color: "919AD1",
    },
    {
      id: 3,
      c_no: 45,
      customer: "고객 3",
      call_Hypo: null,
      color: "B191D1",
    },
    {
      id: 4,
      c_no: 56,
      customer: "고객 4",
      call_Hypo: 222,
      color: "91D1BD",
    },
  ],
};

const customer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // action 생성
    // api로 초기 데이터 설정
    setCustomer: (state, action) => {
      const {customerList} = action.payload;
      state.customerList = customerList;
    },
    // 고객 추가
    createCustomer: (state, action) => {
      const {customer, id} = action.payload;
      // c_no값은 임의로 마지막 배열에서 + 1
      const c_no = state.customerList[state.customerList.length - 1].c_no + 1;
      customer.c_no = c_no;
      customer.id = id;
      console.log(customer);
      const temp = [...state.customerList, customer];
      state.customerList = temp;
    },
    // 고객 삭제
    deleteCustomer: (state, action) => {
      const {deleteId} = action.payload;
      state.customerList = state.customerList.filter((item) => {
        item.id !== deleteId;
      });
    },
    // checked 변경
    setChecked: (state, action) => {
      const {checkValue, id} = action.payload;
      const temp = state.customerList.map((it) =>
        it.id === id ? {...it, call_Hypo: checkValue} : it,
      );
      state.customerList = temp;
    },
    // 전부 체크 해제
    uncheckAllCustomers: (state) => {
      state.customerList.map((item) => {
        item.call_Hypo = null;
      });
    },
  },
});

export const {uncheckAllCustomers, setChecked, createCustomer, deleteCustomer, setCustomer} =
  customer.actions;

// selecter
export const getCustomerList = (state) => state.customer.customerList;

export default customer;
