
import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const todo = createSlice({
  name: "todo",
  initialState,
  reducers: {
    addTodo: (state, action) => {
      state.push(action.payload);
    },
    removeTodo: (state) => {
      return state.slice(0, -1);
    },
    removeAll: () => {
      return initialState;
    },
  },
});

// const increase = createAction('counter/INCREASE');
// 출력 결과 : { type : 'counter/INCREASE'}
// console.log(increase());
// 출력 결과 : { type : 'counter/INCREASE' , payload : 10}
// console.log(increase(10));
// 출력 결과 : 'counter/INCREASE'
// console.log(increase.toString());
// 출력 결과 : 'ACTION type : counter/INCREASE'
// console.log(`ACTION type : ${increase}`) 

export const {addTodo, removeTodo, removeAll} = todo.actions;

export default todo;