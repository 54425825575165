import React from "react";
import {useNavigate} from "react-router-dom";
import image from "@/assets/svg/main_img_04_1.svg";

const EducationLearning = () => {
  const navigate = useNavigate();
  const handleShorcut = () => navigate("/education/list");

  return (
    <div className="learning">
      <div className="educatioInfoContainer">
        <div className="educationContentsBox">
          <div
            className="educationInfoBox right"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <span className="number">04</span>
            <h3 className="title">수료증 발급</h3>
            <p className="description">
              본인이 참여한 교육에 대한 수료증을 발급 받을 수 있습니다.
              <br />
              버튼을 통해 쉽게 발급가능합니다.
            </p>
            <span className="shorcutButton" onClick={handleShorcut}>
              바로가기
              <button>
                <img src={require("@/assets/images/icon_arrow_right_blue.png")} />
              </button>
            </span>
          </div>
          <img
            className="educationInfoImage"
            src={image}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="350"
          />
        </div>
      </div>
    </div>
  );
};

export default EducationLearning;
