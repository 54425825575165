import React from "react";

const MainLocalHeroes = () => {
  const navigateToNaver = () => {
    // Naver로 이동하는 URL
    const naverURL = "https://localheroes.kr/main.php";

    // 새로운 창에서 Naver로 이동
    window.open(naverURL, "_blank");
  };

  return (
    <section className="localHerosWrapper">
      <div className="localHerosContent" onClick={navigateToNaver}>
        <p>지역문제 해결 허브</p>
        <h2>로컬실록지리지</h2>
        <p>
          로컬실록지리지는 지역별 문제를 해결하기 위한 창조적인 플랫폼입니다. <br />
          사용자들이 지도와 게시판을 통해 아이디어를 제공하며, 이는 지역의 독특성을 혁신의 불씨로
          타오르게 합니다.
          <br />
          로컬실록지리지와 함께 지역 문제 해결의 주인공이 되어보세요.
        </p>
      </div>
    </section>
  );
};

export default MainLocalHeroes;
