export const consortiumData = [
  {
    id: 1,
    map: "수도권",
    region: "수도권 컨소시엄",
    general_system: require("@/assets/images/university/system_sudo.png"),
    vocational_system: require("@/assets/images/university/system_v_sudo.png"),
    class: "capital",
    introduction:
      "수도권의 대학·지역 간 창업교육 격차를 해소하고 지자체·창업 주체의 협업 체계를 강화할 수 있는 지역사회 창업교육의 허브로 대학을 육성합니다.",
    general_role: (
      <div>
        <p>START-UP COMMON UNIVERSITY</p>
        <p>“지역 주도의 지속가능한 대학 창업교육 생태계 구축”</p>
        {/* <p>3. 수도권 지역 내 대학 중심의 지역 창업교육(Hub) 구축</p> */}
      </div>
    ),
    vocational_role: (
      <div>
        <p>PLAT TO START-UP</p>
        <p>성과중심 지역기반 창업생태계 전국화 실현</p>
      </div>
    ),
    general_content:
      "지역 및 전문대 특화 맞춤형 실용 창업형 교육과정 도입 및 운영, 지역 중심 대학지원체계(RISE) 연계 강화 및 지역 정주형 실용 창업 활성화",
    vocational_content: "창업교육 통합 플랫폼 구축, 창업친화 제도 강화, 창업인재 양성 활성화, 성과창출 대학의 지역 창업교육 허브(Hub)화 및 지속가능한 지역 정주 창업기업 육성",
    goal: "창업 사각지대에 놓여있는 전문대별 특성화 분야를 중점으로 실전형 창업교육 프로그램을 개발∙확산하여 전문대학 창업 붐 및 지역 창업교육 생태계 조성",
    universities: {
      general: [
        {
          id: 1,
          host: true,
          name: "중앙대",
          fullname: "중앙대학교",
          main: require(`@/assets/images/university/main/중앙대학교.png`),
          manager: "김지현",
          callNumber: "(02-820-6879, serev1358@cau.ac.kr)",
          address: "서울시 동작구 흑석로 84 R&D센터 102관 1002-2호 창업지원단",
          link: "https://www.cau.ac.kr/index.do",
          badge: "수도권 일반대",
          univNo: 316,
          logo: require("@/assets/images/university/university_logo6.png"),
          info: `중앙대학교는 서울 강남 및 경기 서남부의 인프라와 네트워크를 기반으로 창업교육 수요자 중심의 맞춤형 교육을 제공하며, <br/> 지역 내 창업가적 인재양성과 “지역 가치 창업지원” 확산을 목표로 개방형 창업생태계 구축에 기여하고 있습니다.`,
        },
        {
          id: 1,
          name: "고려대",
          fullname: "고려대학교",
          badge: "수도권 일반대",
          main: require(`@/assets/images/university/main/고려대학교.png`),
          manager: "백시연",
          callNumber: "02-3290-4651",
          address: "서울시 성북구 안암로145 고려대학교 CJ식품안전관 103호 크림슨창업지원단",
          link: "https://www.korea.ac.kr/mbshome/mbs/university/index.do",
          univNo: 58,
          image: require("@/assets/images/university/university02.png"),
          logo: require("@/assets/images/university/university_logo7.png"),
          info: "고려대학교 크림슨창업지원단은 2018년부터 연구부총장 직속 기관으로 새롭게 출범한 고려대 창업지원의 컨트롤타워입니다. 다양한 정부지원사업 수주를 통해 창업동아리부터 3년 미만의 초기창업기업을 집중 육성하고 있으며, 창업보육센터(BI) 및 메이커스페이스(KUmakerspace, X-GARAGE, KU-3DS)를 운영하면서 학생들의 아이디어 구체화와 사업화를 지원합니다. 학부 기술창업융합전공, 대학원 첨단기술비즈니학과를 운영하면서 창업휴학, 창업 현장실습, 창업대체학점인정제 등 창업친화적 학제 구축을 강화하고 있습니다.",
        },
        {
          id: 1,
          name: "이화여대",
          fullname: "이화여자대학교",
          main: require(`@/assets/images/university/main/이화여자대학교.png`),
          manager: "강다윤",
          callNumber: "02-3277-5082",
          address: "서울특별시 서대문구 이화여대길 52",
          link: "https://www.ewha.ac.kr/ewha/index.do",
          badge: "수도권 일반대",
          univNo: 284,
          image: require("@/assets/images/university/university03.png"),
          logo: require("@/assets/images/university/university_logo8.png"),
          info: "이화여자대학교는 기업가정신 확산을 위해 이화 DNA 기반의 혁신적 교육 프로그램을 개발 및 제공하고, 여성을 비롯한 모든 창업가들의 도전과 성장을 지원합니다.",
        },
        {
          id: 1,
          name: "인하대",
          fullname: "인하대학교",
          main: require(`@/assets/images/university/main/인하대학교.png`),
          manager: "박예린",
          callNumber: "032-860-9151",
          address: "인천광역시 미추홀구 인하로 100, 인하대학교 본관001호 창업지원단",
          link: "https://www.inha.ac.kr/kr/index.do",
          badge: "수도권 일반대",
          univNo: 293,
          image: require("@/assets/images/university/university04.png"),
          logo: require("@/assets/images/university/university_logo9.png"),
          info: "인하대학교는 이공계 융합공학 역량을 바탕으로 우수한 창업교육과 인프라를 제공하여 인천 지역 내 창업 인재 양성을 위한 창업지원에 앞장섭니다.",
        },
        {
          id: 1,
          name: "한국공학대",
          fullname: "한국공학대학교",
          main: require(`@/assets/images/university/main/한국공학대학교.png`),
          manager: "전용수",
          callNumber: "031-8041-0996",
          address: "기술혁신파크(TIP) 313호",
          link: "https://www.tukorea.ac.kr/tukorea/index.do",
          badge: "수도권 일반대",
          univNo: 347,
          image: require("@/assets/images/university/university05.png"),
          logo: require("@/assets/images/university/university_logo10.png"),
          info: `
            <p>
              한국공학대학교는 실사구시(實事求是)건학이념을 바탕으로, <strong>글로벌</strong>과 <strong>로컬</strong>창업 역량 강화를
              위한 프로그램 기획 및 운영을 통해
              <br /> 공학 중심의 글로컬 창업 인재 육성을 목표로 합니다.
            </p>
          `,
        },
      ],
      vocational: [
        {
          id: 2,
          host: true,
          name: "동양미래대학교",
          fullname: "동양미래대학교",
          main: require(`@/assets/images/university/main/동양미래대학교.png`),
          manager: "이건우",
          callNumber: "(02-2610-1896, hyacinth@donayang.ac.kr)",
          address: "서울특별시 구로구 경인로 445(동양미래대학교) 1호관(대학본부) 4층, 401호",
          link: "https://www.dongyang.ac.kr/dongyang/index.do",
          badge: "수도권 전문대",
          univNo: 142,
          logo: require("@/assets/images/university/동양미래대학교.png"),
          info: "동양미래대학교는 「성실」, 「근검」, 「박애」을 학훈으로 산업 기술 분야의 전문지식 지식ㆍ기술을 교수하고 건전한 직업 윤리관을 갖추도록 인성 교육을 강화하여, 국가 산업 발전에 이바지하고 인류 사회에 봉사할 창의적이고 슬기로운 지도자급 전문직업인을 양성하고 배출하는데 기여하고 있습니다.",
        },
        {
          id: 2,
          name: "서일대",
          fullname: "서일대학교",
          main: require(`@/assets/images/university/main/서일대학교.png`),
          manager: "고동우",
          callNumber: "(02-490-7352, 20220136@seoil.ac.kr)",
          address: "서울특별시 중랑구 용마산로 90길 28(서일대학) 흥학관 3층, 303호",
          link: "https://www.seoil.ac.kr/seoil/index.do",
          badge: "수도권 전문대",
          univNo: 200,
          logo: require("@/assets/images/university/서일대학교.png"),
          info: `서일대학교는 「지덕배양」, 「초지일관」을 교훈으로 창의·융합·인성을 갖춘 자기주도형 인재를 양성하고, 지역 정주형 예비 창업인 및 선도 창업인을 배출하는데 기여하고 있습니다.`,
        },
        {
          id: 2,
          name: "재능대",
          fullname: "재능대학교",
          main: require(`@/assets/images/university/main/재능대학교.png`),
          manager: "신창환",
          callNumber: "032-890-7040",
          address: "인천시 동구 재능로178 재능관 3층 창업지원센터",
          link: "https://jeiu.ac.kr/",
          badge: "수도권 전문대",
          univNo: 296,
          logo: require("@/assets/images/university/재능대학교.png"),
          info: `<strong>A Better Life Through Better Education</strong> <br/> 변화는 나를 성장시킬 수 있는 기회! 더 나은 나를 발견할 수 있는 곳! <br/> 글로벌평생직업교육대학 재능대학교`,
        },
        {
          id: 2,
          name: "대림대",
          fullname: "대림대학교",
          main: require(`@/assets/images/university/main/대림대학교.png`),
          manager: "김상범",
          callNumber: "031-467-4631",
          address: "경기도 안양시 동안구 임곡로 29 대림대학교 본관 3층 창의창업교육센터",
          link: "https://www.daelim.ac.kr/intro.do",
          badge: "수도권 전문대",
          univNo: 119,
          logo: require("@/assets/images/university/대림대학교.png"),
          info: `세계 최고의 특성화된 전문직업 교육기관 <strong>ACE : Active, Creative, Ethical</strong>`,
        },
      ],
    },
  },
  {
    id: 2,
    map: "충청권",
    region: "충청권 컨소시엄",
    general_system: require("@/assets/images/university/system_choong.jpg"),
    vocational_system: require("@/assets/images/university/system_v_choong.jpg"),
    class: "chungcheong",
    introduction:
      "충청권(대전, 세종, 충남, 충북) 창업교육 공유·협력으로 대학 간·지역 간 창업교육 격차를 해소하고, 지자체 및 지역 창업주체와의 협업체계 강화를 통해 혁신인재의 실전 창업교육을 지원",
    general_role: (
      <div>
        <p>
          충청권(대전, 세종, 충남, 충북) 창업교육 공유·협력으로 대학 간·지역 간 창업교육 격차를
          해소하고, 지자체 및 지역 창업주체와의 협업체계 강화를 통해 혁신인재의 실전 창업교육을 지원
        </p>
      </div>
    ),
    vocational_role: (
      <div>
        <p>
        Startup MAPS
        </p>
        <p>
        창의적 실전 창업교육으로 충청권 특화형 창업교육 생태계 조성
        </p>
      </div>
    ),
    general_content:
      "창업교육 대학컨설팅을 통해 미참여대학의 창업교육 선도모델, 미참여대학 창업동아리 상시멘토링, 창업교육실무자 역량강화, 학점교류제, 창업친화적 학사/인사제도 도입에 핵심역량 투입",
    vocational_content: "권역 창업교육 인프라 구축, 창의적 실전 창업교육 고도화, 지역 특화형 창업교육 체제 구축을 추진전략으로 지역과 함께 성장·발전하는 창업 교육 운영",
    goal: "지역 중심의 창업교육 프로그램 개발 확산, 창업교육 콘텐츠 개발, 지역 창업교육 협의체 구성 등",
    universities: {
      general: [
        {
          id: 3,
          host: true,
          name: "충남대",
          main: require(`@/assets/images/university/main/충남대학교.png`),
          manager: "김진경",
          callNumber: "042-821-5964",
          address: "대전광역시 유성구 대학로 99",
          link: "https://plus.cnu.ac.kr/html/kr/",
          fullname: "충남대학교",
          badge: "충청권 일반대",
          univNo: 335,
          logo: require("@/assets/images/university/충남대학교.png"),
          info: "미래 사회를 선도할 강한 대학, 충남대학교는 대전·세종·충남지역의 지역거점국립대학교로서 대덕연구개발특구, 세종특별자치시, 충청남도 내포신도시를 아우르는 초광역 메가 캠퍼스를 조성하고 있으며, 4차 산업혁명 시대의 변화에 적극적으로 대응하고 지속 가능한 발전을 선도하고 있습니다.",
        },
        {
          id: 3,
          name: "고려대 세종",
          fullname: "고려대학교 세종",
          main: require(`@/assets/images/university/main/고려대세종.png`),
          manager: "김동천",
          callNumber: "044-860-3819",
          address: "세종특별자치시 조치원읍 세종로2511, 과학기술1관 611호",
          link: "https://sejong.korea.ac.kr/kr",
          badge: "충청권 일반대",
          univNo: 59,
          logo: require("@/assets/images/university/고려대학교세종.png"),
          info: "대한민국의 새로운 중심, 세종특별자치시에 위치한 고려대학교 세종캠퍼스는 글로벌 레지던스 시스템으로 대학 교육의 새로운 패러다임을 제시하며 전인교육의 새 지평을 열어왔습니다. 우리 대학은 변화를 꿈꾸며 거침없이 도전하는 미래의 기업가를 위한 성장형 창업 교육 프로그램을 지원합니다.",
        },
        {
          id: 3,
          name: "순청향대",
          fullname: "순천향대학교",
          main: require(`@/assets/images/university/main/순천향대학교.png`),
          manager: "이나영",
          callNumber: "041-530-4860",
          address: "충남 아산시 신창면 순천향로 22, 공학관 2F_9226",
          link: "https://www.sch.ac.kr/",
          badge: "충청권 일반대",
          univNo: 227,
          logo: require("@/assets/images/university/순천향대학교.png"),
          info: "순천향대학교는 다양한 창업 교육 프로그램과 인프라를 통해 학생들의 창업 역량을 강화하고 창의적인 사업 아이디어를 현실로 구현할 수 있는 환경을 제공하고 있습니다.",
        },
        {
          id: 3,
          name: "충북대",
          fullname: "충북대학교",
          main: require(`@/assets/images/university/main/충북대학교.png`),
          manager: "교학창업지원팀",
          callNumber: "043-249-1473",
          address: "충북 청주시 서원구 충대로1, E3-1(NH관) 3층 교학창업지원팀",
          link: "https://www.cbnu.ac.kr/www/index.do",
          badge: "충청권 일반대",
          univNo: 337,
          logo: require("@/assets/images/university/충북대학교.png"),
          info: "우리 대학은 지역 사회의 창업 교육 기반을 구축하고, (예비)창업자에게 전주기적 창업지원에 힘쓰며, 특히, 예비창업자에게 필요한 BM 고도화, MVP 제작 등 창업교육 프로그램을 운영하고, 기창업자에게는 IR교육, 투자유치, 지역 내 네트워킹 지원 등 실질적 사업 운영에 필요한 프로그램들을 운영하고 있습니다.",
        },
      ],
      vocational: [
        {
          id: 4,
          host: true,
          name: "강동대",
          fullname: "강동대학교",
          main: require("@/assets/images/university/main/강동대.png"),
          manager: "이명훈",
          callNumber: "(043-879-3496, zpzp2328@gangdong.ac.kr)",
          address: "충북 음성군 감곡면 대학길 278, 412호",
          link: "https://www.gangdong.ac.kr/intro/main/index.do",
          badge: "충청권 전문대",
          univNo: 13,
          logo: require("@/assets/images/university/강동대학교.png"),
          info: `강동대학교는 홍익인간의 교육이념을 구현하고자 성실, 창조, 협동의 실천을 통해 국가와 민족 그리고 인류 공영에 이바지하는 인간교육을 실천하고, 4차 산업혁명을 선도하는 사회수요 맞춤 창의인재를 양성하고 있습니다.`,
        },
        {
          id: 4,
          name: "우송정보대학",
          fullname: "우송정보대학",
          main: require("@/assets/images/university/main/우송대.png"),
          manager: "권영민",
          callNumber: "(042-630-9688, time@wsi.ac.kr)",
          address: "대전광역시 동구 백룡로 59 우송정보대학 테크노디자인센터 207호 창업교육센터",
          link: "https://www.wsi.ac.kr/main/index.jsp",
          badge: "충청권 전문대",
          univNo: 270,
          logo: require("@/assets/images/university/우송대학교.png"),
          info: `우송정보대학은 다양한 창업관련 지원 프로그램을 통해 창업문화를 확산하고 학생들의 창의적이고 혁신적인 아이디어를 지원하고 있습니다. 창업을 위한 프로그램 개발, 인프라 및 네트워크 구축을 통해 청년창업 활성화 및 체계적인 창업교육을 지원합니다.`,
        },
        {
          id: 4,
          name: "충북도립대",
          fullname: "충북도립대학교",
          main: require("@/assets/images/university/main/충북도립대.png"),
          manager: "조주연",
          callNumber: "(043-220-5380, chojy2011@cpu.ac.kr)",
          address: "충청북도 옥천군 옥천읍 대학길 15 정보관 201호",
          link: "https://www.cpu.ac.kr/home/main.do",
          badge: "충청권 전문대",
          univNo: 338,
          logo: require("@/assets/images/university/충북도립대학교.png"),
          info: `충북도립대학교는 학생 창업지원 활성화를 통해 창조적 기술인을 양성하며, 캠퍼스 내 창업 문화를 조성하고 창업 분위기를 확산시켜 학생들에게 창업 마인드를 고취시키고 있습니다. 다양한 창업 관련 지원 프로그램을 통해 창의적 실전 창업교육으로 지역 특화형 창업교육 생태계를 조성하고 있습니다.`,
        },
        {
          id: 4,
          name: "충북보건과학대",
          fullname: "충북보건과학대학교",
          main: require("@/assets/images/university/main/충북보건과학대.png"),
          manager: "김기원",
          callNumber: "(043-210-8167, gwkim@chsu.ac.kr)",
          address: "충북 청주시 청원구 내수읍 덕암길10 품성관 105호 취업창업지원센터",
          link: "https://www.chsu.ac.kr/CmsHome/MainDefault.aspx",
          badge: "충청권 전문대",
          univNo: 339,
          logo: require("@/assets/images/university/충북보건과학대학교.png"),
          info: `충북보건과학대학교는 충북 청주를 중심으로 충북지역 기반의 대학 창업교육 생태계 구축을 위하여 대학 및 지역 간의 창업교육의 공유·협력으로 지역 협업체계를 강화하고 혁신인재의 실전 창업을 지원하도록 한다.`,
        },
        {
          id: 4,
          name: "연암대",
          fullname: "연암대학교",
          main: require("@/assets/images/university/main/연암대학교.png"),
          manager: "이태희",
          callNumber: "041-580-1015",
          address: "충남 천안시 서북구 성환읍 연암로 313 연암대학교 본관 1층 창취업성공지원센터",
          link: "https://www.yonam.ac.kr/mbshome/mbs/kr/index.do",
          badge: "충청권 전문대",
          univNo: 251,
          logo: require("@/assets/images/university/연암대학교.png"),
          info: `연암대학교는 “국내 최고의 차세대 농업 기술 선도 대학”이라는 비전을 이행하기 위하여 특성화 전략을 수립하여 운영하고 있으며, 차세대농업기술 융복합 교육을 통해 농업 분야 실무형 최고 전문가를 양성하여 우리나라 농업 발전에 이바지하고자 합니다.`,
        },
      ],
    },
  },
  {
    id: 3,
    map: "호남 제주권",
    region: "호남 제주권 컨소시엄",
    general_system: require("@/assets/images/university/system_jeju.png"),
    vocational_system: require("@/assets/images/university/system_v_jeju.png"),
    class: "honam_jeju",
    introduction:
      "호남 제주권의 대학·지역 간 창업교육 격차를 해소하고 지자체·창업 주체의 협업 체계를 강화할 수 있는 지역사회 창업교육의 허브로 대학을 육성합니다.",
    general_role: (
      <div>
        <p>대학 창업교육 혁신을 통한 호남·제주권 대학의 창업 경쟁력 제고</p>
      </div>
    ),
    vocational_role: (
      <div>
        <p>
          창업 정규·비정규 교육과정을 개발, 운영하고 창업친화적 학사·인사제도를 운영·개선하며, 지역
          간/대학 간 창업격차 해소를 위한 지역협의체 운영·지역 특화형 창업교육 프로그램을
          운영함으로써 창업교육 거점대학 역할 수행
        </p>
      </div>
    ),
    goal: "호남제주권 SCOUT사업단의 창업교육혁신플랫폼(4UP)을 중심으로 컨소시엄대학과 지역창업혁신기관, 지자체 및 투자기관의 유기적 협조 체계 구축",
    general_content: (
      <div>
        <p>1. 창업교육 선도모델 기반 창업교육 역량 상향평준화</p>
        <p>2. 지역특화 연계 고부가가치 창업 활성화</p>
        <p>3. 대학중심의 지역 창업교육 혁신허브 조성</p>
      </div>
    ),
    vocational_content:
      "호남제주권 각 권역별 대학이 컨소시엄을 구성하여 창업친화적 제도 및 교육 환경을 조성하고, 창업 관련 조직의 거버넌스를 형성하여, 창업 인프라 전반을 지원하는 제조원 창업교육 생태계 구축",
    universities: {
      general: [
        {
          id: 5,
          host: true,
          name: "전남대",
          fullname: "전남대학교",
          main: require(`@/assets/images/university/main/전남대학교.png`),
          manager: "기우민",
          callNumber: "062-530-5080",
          address: "광주광역시 북구 용봉로77 전남대학교 G&R Hub 420호",
          link: "https://www.jnu.ac.kr/jnumain.aspx",
          badge: "호남 제주권 일반대",
          univNo: 298,
          logo: require("@/assets/images/university/전남대학교.png"),
          info: "창업지원 인프라 및 역량을 결집하여 기업가정신을 제고하며 창업문화를 확산하기 위하여, 창업과 관련한 전 분야의 지원과 인프라를 구축하고 창업을 지원합니다.",
        },
        {
          id: 5,
          name: "국립목포대",
          fullname: "국립목포대학교",
          main: require(`@/assets/images/university/main/목포대학교.png`),
          manager: "이균범",
          callNumber: "061-450-6378",
          address: "전라남도 무안군 영산로 1666 플라자60 7층",
          link: "https://www.mokpo.ac.kr/www/index.do",
          badge: "호남 제주권 일반대",
          univNo: 78,
          logo: require("@/assets/images/university/목포대학교.png"),
          info: "지역과 함께 성장하는 창업기업을 육성하는 국립목포대학교입니다. 지역의 특성 맞춤 창의적 사고와 전문성을 함양하며 디지털 창업·로컬창업 특성화 기반으로 학생 창업자 발굴합니다. ",
        },
        {
          id: 5,
          name: "국립순천대",
          fullname: "국립순천대학교",
          main: require(`@/assets/images/university/main/국립순천대학교.png`),
          manager: "황다영",
          callNumber: "061-750-5455",
          address: "전라남도 순천시 중앙로 235(순천대학교) 국제문화컨벤션관 3층 314호",
          link: "https://www.scnu.ac.kr/SCNU/main.do",
          badge: "호남 제주권 일반대",
          univNo: 81,
          logo: require("@/assets/images/university/순천대학교.png"),
          info: "우리 대학은 남다른 발상과 차별화 전략을 추구하며 창의적인 생각을 존중하고 구체화하기 위해 열린 창업캠퍼스를 통한 창업 친화 교육프로그램을 지원하고, 물적·인적 지원을 통한 창업사업화를 지원함으로써 창업거점이 되고자 노력하고 있습니다.",
        },
        {
          id: 5,
          name: "원광대",
          fullname: "원광대학교",
          main: require(`@/assets/images/university/main/원광대학교.png`),
          manager: "김수빈",
          callNumber: "063-850-5862",
          address: "전북특별자치도 익산시 익산대로 460 원광대학교 학생지원관 3층",
          link: "https://www.wku.ac.kr/",
          badge: "호남 제주권 일반대",
          univNo: 275,
          logo: require("@/assets/images/university/원광대학교.png"),
          info: "우리대학은 체험·실습형 창업 교육 프로그램 운영을 통해 학생들이 실전지향형 창업 인재로 거듭날 수 있도록 지원하고 있습니다.",
        },
        {
          id: 5,
          name: "제주대",
          fullname: "제주대학교",
          main: require(`@/assets/images/university/main/제주대학교.png`),
          manager: "김소희",
          callNumber: "064-754-4412~4462",
          address: "제주특별자치도 제주시 제주대학로 102 산학협력4관 4층 408호",
          link: "https://www.jejunu.ac.kr/",
          badge: "호남 제주권 일반대",
          univNo: 310,
          logo: require("@/assets/images/university/제주대학교.png"),
          info: "기업가마인드 제고를 위해 필요한 관련 지식·기술을 가르치는 대학의 교육과정과 예비창업자로서 갖추어야 할 경영지식, 가치관, 태도 등을 함양하는 일체의 활동을 지원합니다.",
        },
      ],
      vocational: [
        {
          id: 6,
          host: true,
          name: "조선이공대",
          fullname: "조선이공대학교",
          main: require(`@/assets/images/university/main/조선이공대학교.png`),
          manager: "고경주",
          callNumber: "062-230-8420",
          address: "광주광역시 동구 조선이공대길 11(서석동, 조선이공대학교) 본부동 2층 SCOUT사업단",
          link: "https://www.cst.ac.kr/",
          badge: "호남 제주권 전문대",
          univNo: 314,
          logo: require("@/assets/images/university/조선이공대학교.png"),
          info: "우리대학의 창업교육 프로그램은 산학협력을 통해 특허, 기술, 사람은 물론이고 시설, 연구장비 등 대학이 보유한 자원을 활용하여 창업역량을 강화하고 지역사회 특화분야 창업인력 양성을 위한 프로그램을 지원하고 있습니다. 학생들의 아이디어를 현실로 구현하고 성공적인 창업·기업가로 성장할 수 있도록 지원합니다.",
        },
        {
          id: 6,
          name: "원광보건대",
          fullname: "원광보건대학교",
          main: require(`@/assets/images/university/main/원광보건대학교.png`),
          manager: "이병훈 팀장",
          callNumber: "063-840-1482",
          address: "전북특별자치도 익산시 익산대로 514, 원광보건대학교 멀티미디어센터 2층",
          link: "https://www.wu.ac.kr/wu/index.do",
          badge: "호남 제주권 전문대",
          univNo: 277,
          logo: require("@/assets/images/university/원광보건대학교.png"),
          info: "우리 대학은 대학 자체 개발하여 특성화된 LTM 교육 체계 내 창의 Biz-9 Game 창업교육 기반 지역사회의 창업 생태계 플랫폼 역할 수행을 통해 관·산·학 협력 창업 혁신 공동성장 및 청년 우수 인재 양성을 목표로 하는 전문대학 최초 창업교육 우수대학 입니다.",
        },
        {
          id: 6,
          name: "제주관광대",
          fullname: "제주관광대학교",
          main: require("@/assets/images/university/main/제주관광대학교.png"),
          manager: "이성민",
          callNumber: "064-740-8818",
          address:
            "제주특별자치도 제주시 애월읍 평화로 2715 (제주관광대학교) 초운관 2층 산학협력단사업단",
          link: "https://www.jtu.ac.kr/",
          badge: "호남 제주권 전문대",
          univNo: 308,
          logo: require("@/assets/images/university/제주관광대학교.png"),
          info: "제주관광대학교 창업교육지원센터는 산학협력친화적 선도모델 구현을 통한 제주 지역 산업 가치를 향상시키는 로컬 콘텐츠 활용한 특화분야 창업 역량 강화를 위한 교육과 프로그램을 지원하고 있습니다. 창업과 산업체 참여형 캡스톤디자인을 통해 미래산업을 선도하는 오픈 콜라보레이션 대학의 역할을 수행하고 성공적인 창업 여정을 현실화 시키기위한 노력을 다하겠습니다.",
        },
      ],
    },
  },
  {
    id: 4,
    map: "대경 강원권",
    region: "대경 강원권 컨소시엄",
    general_system: require("@/assets/images/university/system_daekyeong.jpg"),
    vocational_system: require("@/assets/images/university/system_v_kangwon.png"),
    class: "kangwon",
    introduction:
      "대경 강원권의 대학·지역 간 창업교육 격차를 해소하고 지자체·창업 주체의 협업 체계를 강화할 수 있는 지역사회 창업교육의 허브로 대학을 육성합니다.",
    general_role: (
      <div>
        <p>
          D.E.E.P 유형 URI(University-Region-Institute) 창업교육 혁신 생태계를 구축하고, 대경 강원권
          URI SYSTEM 구축을 통한 창업교육 활성화를 통한 지역청년 정주 유도
        </p>
      </div>
    ),
    vocational_role: (
      <div>
        <p>
          전문대학 창업 특화 프로그램 및 콘텐츠 공동 개발 운영 등 지역 지자체와 대학간 창업교육
          격차해소를 위한 창업교육 허브 구축
        </p>
      </div>
    ),
    general_content:
      "창업교육 혁신체계를 구축하고 지역특화 창업교육 프로그램을 운영해 창업교육 허브 기능을 수행",
    vocational_content:
      "지역 및 전문대 특화 맞춤형 실용 창업형 교육과정 도입 및 운영, 지역 중심 대학지원체계(RISE) 연계 강화 및 지역 정주형 실용 창업 활성화",
    goal: "지역 중심의 창업교육 프로그램 개발 확산, 창업교육 콘텐츠 개발, 지역 창업교육 협의체 구성 등",
    universities: {
      general: [
        {
          id: 7,
          host: true,
          name: "영남대",
          fullname: "영남대학교",
          main: require(`@/assets/images/university/main/영남대학교.png`),
          manager: "김경태",
          callNumber: "053-810-1595~8",
          address: "경상북도 경산시 대학로 280 CRC 505호, 506호 SCOUT사업단",
          link: "https://www.yu.ac.kr/main/index.do",
          badge: "대경 강원권 일반대",
          univNo: 252,
          logo: require("@/assets/images/university/영남대학교.png"),
          info: "우리 대학에서는 국가와 지역을 선도하는 글로벌 기업가를 육성하고, 지역전략산업과 동반성장하는 혁신형 창업생태계 구축을 위해 노력하고 있습니다.",
        },
        {
          id: 7,
          name: "강릉원주대",
          fullname: "강릉원주대학교",
          main: require(`@/assets/images/university/main/국립강릉원주대학교.png`),
          manager: "김세미",
          callNumber: "033-640-2843",
          address: "강원특별자치도 강릉시 죽헌길7 산학협력관(N10) 5층 501호 창업지원본부",
          link: "https://www.gwnu.ac.kr/sites/kr/index.do",
          badge: "대경 강원권 일반대",
          univNo: 73,
          logo: require("@/assets/images/university/강릉원주대학교.png"),
          info: "국립강릉원주대학교는 인간 존중 교육을 통해 역량과 인성을 갖춘 인재를 양성합니다. 교육의 내실화를 통해 4차 산업혁명 시대가 요구하는  창의·융합형 인재 양성은 물론 국가 평생직업능력개발을 선도하고자 합니다.",
        },
        {
          id: 7,
          name: "계명대",
          fullname: "계명대학교",
          main: require(`@/assets/images/university/main/계명대학교.png`),
          manager: "신은경",
          callNumber: "053-580-6772",
          address: "대구 달서구 달구벌대로 1095 계명대학교 산학협력관 309호",
          link: "https://www.kmu.ac.kr/",
          badge: "대경 강원권 일반대",
          univNo: 55,
          logo: require("@/assets/images/university/계명대학교.png"),
          info: "계명대학교는 전 주기적 Start-Up 교육과 지역미래산업 생태계를 연계한 세계를 향해 빛을 여는 글로컬창업 선도대학을 지향합니다.",
        },
        {
          id: 7,
          name: "안동대",
          fullname: "안동대학교",
          main: require(`@/assets/images/university/main/안동대학교.png`),
          manager: "김보혜",
          callNumber: "054-820-7418",
          address: "경상북도 안동시 경동로 1375 지역산학협력관 305호 창업보육센터",
          link: "https://www.andong.ac.kr/main/index.do",
          badge: "대경 강원권 일반대",
          univNo: 82,
          logo: require("@/assets/images/university/안동대학교.png"),
          info: "국립안동대학교는 학생을 최우선으로 생각하는 대학경영을 중점으로 지역·학생의 상생 발전, 학생 중심의 대학 경영, 지역과 연계한 연구·산학 그리고 학생 맞춤 교육 혁신의 상호보완적이며 통합적인 실행을 통해 지속 가능한 성장과 함께 학생역량강화 경북거점국립대로 도약할 것입니다.",
        },
        {
          id: 7,
          name: "포항공대",
          fullname: "포항공과대학교",
          main: require(`@/assets/images/university/main/포항공대.png`),
          manager: "전진영",
          callNumber: "054-279-9284",
          address: "경상북도 포항시 남구 청암로 87(체인지업그라운드)",
          link: "https://www.postech.ac.kr/kor/",
          badge: "대경 강원권 일반대",
          univNo: 343,
          logo: require("@/assets/images/university/포항공과대학교.png"),
          info: "포항공과대학교가 보유한 세계적 수준의 R&BD 역량과 인프라를 바탕으로, 창업 동기부여, 아이템 발굴 및 검증, 후속 성장지원에 이르는 전주기 지원 프로그램을 통해 유망 스타트업을 육성하여 인류사회 발전에 이바지하고자 합니다.",
        },
      ],
      vocational: [
        {
          id: 8,
          host: true,
          name: "대경대",
          fullname: "대경대학교",
          main: require(`@/assets/images/university/main/대경대학교.png`),
          address: "경북 경산시 자인면 단북1길 65 본관 3층 SCOUT사업단",
          manager: "이지현",
          callNumber: "053-850-1328",
          link: "https://www.tk.ac.kr/",
          badge: "대경 강원권 전문대",
          univNo: 106,
          logo: require("@/assets/images/university/대경대학교.png"),
          info: "우리대학의 창업교육 프로그램은 창의적인 도전정신을 갖춘 실천형 창업가 양성을 위해 창업 관련 기초지식과 건전한 기업가정신 등의 교육을 제공합니다.",
        },
        {
          id: 8,
          name: "강원도립대",
          fullname: "강원도립대학교",
          main: require(`@/assets/images/university/main/강원도립대학교.png`),
          manager: "장경환",
          callNumber: "033-660-8096",
          address:
            "강원특별자치도 강릉시 주문진읍 연주로 270(교항리) 강원도립대학교 산학협력관 1층 111호 행정실",
          link: "https://www.gw.ac.kr/",
          badge: "대경 강원권 전문대",
          univNo: 19,
          logo: require("@/assets/images/university/강원도립대학교.png"),
          info: "강원도립대학교는 진리, 창조, 봉사의 정신을 바탕으로 지역사회를 이끌어 갈 창의융합 인재를 육성하는 요람으로 1998년 3월 개교한 강원특별자치도 유일의 공립대학입니다.",
        },
        {
          id: 8,
          name: "계명문화대",
          fullname: "계명문화대학교",
          main: require(`@/assets/images/university/main/계명문화대학교.png`),
          manager: "윤상필",
          callNumber: "053-589-7725",
          address: "대구광역시 달서구 달구벌대로 675 계명문화대학교 복지관 취·창업지원센터",
          link: "https://www.kmcu.ac.kr/kr/",
          badge: "대경 강원권 전문대",
          univNo: 56,
          logo: require("@/assets/images/university/계명문화대학교.png"),
          info: "계명문화대학교는 문화/예술/디자인 IT융합 기술 분야 Start-Up 교육과 도전하는 지역 내 예비 창업인 및 선도 창업인의 생태계를 구축하는데 기여하고 있습니다.",
        },
        {
          id: 8,
          name: "대구보건대",
          main: require(`@/assets/images/university/main/대구보건대.png`),
          manager: "유채형",
          callNumber: "053-320-5617",
          address: "대구광역시 북구 영송로 15",
          link: "https://www.dhc.ac.kr/",
          fullname: "대구보건대학교",
          badge: "대경 강원권 전문대",
          univNo: 113,
          logo: require("@/assets/images/university/대구보건대학교.png"),
          info: "대구보건대학교 DHC SCOUT 사업단은 창업교육 분야의 선도적 역할을 맡고 있습니다. 학생들에게 창의력과 혁신적 사고를 길러주며, 창업에 필요한 교육과 기회를 제공합니다. 우리는 미래를 준비하는 창업가들의 동반자로서, 지속적인 지원과 네트워킹을 통해 창업 생태계를 활성화하고 있습니다.",
        },
      ],
    },
  },
  {
    id: 5,
    map: "동남권",
    region: "동남권 컨소시엄",
    class: "dongnam",
    general_system: require("@/assets/images/university/system_dongnam.jpg"),
    vocational_system: require("@/assets/images/university/system_v_dongnam.png"),
    introduction:
      "동남권의 대학·지역 간 창업교육 격차를 해소하고 지자체·창업 주체의 협업 체계를 강화할 수 있는 지역사회 창업교육의 허브로 대학을 육성합니다.",
    general_role: (
      <div>
        <p>
          4차 산업에 대응하기 위한 지역 특화산업 혁신에 역량을 제공하며, 지역내 건전한 창업생태계
          조성을 위한 창업인력 정주
        </p>
      </div>
    ),
    vocational_role: (
      <div>
        <p>도전 정신을 가진 창의적 청년들의 글로벌 창업 역량강화</p>
        <p>창업 생태계 조성을 통한 예비 창업자 500명 양성</p>
      </div>
    ),
    general_content: (
      <div>
        <p>지역 창업교육 허브를 구축, 창업 친화적 대학 문화 조성</p>
        <p>
          도전하는 학생을 위한 열린 창업 지원체계 구축, 맞춤형 창업교육으로 학생의 창업역량 강화
        </p>
      </div>
    ),
    vocational_content:
      "실전창업 교육과정 운영으로 창생의 창업 역량 강화 및 실용기술 기반 창업지원, 지자체와 지역창업지원 기관 연계 프로그램 운영을 통한 지역 산업기반 정주형 창업문화 확산도모",
    goal: "지역 중심의 창업교육 프로그램 개발 확산, 창업교육 콘텐츠 개발, 지역 창업교육 협의체 구성 등",
    universities: {
      general: [
        {
          id: 9,
          host: true,
          name: "국립부경대",
          fullname: "국립부경대학교",
          main: require(`@/assets/images/university/main/부경대학교.png`),
          manager: "허준",
          callNumber: "051-629-6369",
          address: "부산광역시 남구 용소로 45 동원장보고관 308호 SCOUT 사업단",
          link: "https://www.pknu.ac.kr/main",
          badge: "동남권 일반대",
          univNo: 80,
          logo: require("@/assets/images/university/부경대학교.png"),
          info: "동남권 창업 교육시스템을 고도화하고 지역 창업생태계 혁신을 선도합니다. 또한, 동남권 지역 특성 반영 및 창업성공사례 확보 중심의 집중 양성 프로그램을 지원합니다.",
        },
        {
          id: 9,
          name: "경상국립대",
          fullname: "경상국립대학교",
          main: require(`@/assets/images/university/main/경상국립대학교.png`),
          manager: "권예희",
          callNumber: "055-772-4686",
          address:
            "경상남도 진주시 진주대로 501 경상국립대학교 가좌캠퍼스 28동 202호 동남권 SCOUT 사업단",
          link: "https://www.gnu.ac.kr/main/main.do",
          badge: "동남권 일반대",
          univNo: 45,
          logo: require("@/assets/images/university/경상국립대학교.png"),
          info: "경상국립대학교는 2023년 창업중심대학 사업, 그린스타트업 조성사업, 창업교육혁신선도대학(SCOUT) 사업, 2024년 로컬콘텐츠중점대학 사업에 연이어 선정되어 지역 창업생태계를 더욱 활성화하며, 지역 혁신창업의 전초기지로 거듭나고 있습니다.",
        },
        {
          id: 9,
          name: "울산대",
          fullname: "울산대학교",
          main: require(`@/assets/images/university/main/울산대학교.png`),
          manager: "박규리",
          callNumber: "052-259-1335",
          address: "울산광역시 남구 대학로 93, 35호관 302호 창업지원단",
          link: "https://www.ulsan.ac.kr/kor/Main.do",
          badge: "동남권 일반대",
          univNo: 273,
          logo: require("@/assets/images/university/울산대학교.png"),
          info: "울산대학교 SCOUT 사업단은 창업 전문교육과 멘토링 중심의 현장실습을 통해 대학생들이 구체적으로 자신의 창업 비젼을 실현해 나갈 수 있도록 지원하는 조직입니다.",
        },
        {
          id: 9,
          name: "국립창원대",
          fullname: "국립창원대학교",
          main: require(`@/assets/images/university/main/창원대학교.png`),
          manager: "노경희",
          callNumber: "055-213-2806",
          address: "경상남도 창원시 의창구 창원대학로 20, 국립창원대학교 81호관 403호 SCOUT 사업단",
          link: "https://www.changwon.ac.kr/kor/main.do?intro=OFF",
          badge: "동남권 일반대",
          univNo: 83,
          logo: require("@/assets/images/university/창원대학교.png"),
          info: "국립창원대학교 SCOUT 사업단은 지역에 특화한 창업 교육과 전문‧실전 창업형 교육을 운영하여 지역 창업생태계를 활성화할 수 있도록 지원합니다. 또한, 창업교육에서 지역 창업까지 이어질 수 있도록 동남권 대학창업의 허브역할을 수행합니다.",
        },
      ],
      vocational: [
        {
          id: 10,
          host: true,
          name: "울산과학대",
          fullname: "울산과학대학교",
          main: require(`@/assets/images/university/main/울산과학대학교.png`),
          manager: "박한솔",
          callNumber: "052-230-0425",
          address: "울산광역시 동구 봉수로 101 3대학관 S-203",
          link: "https://www.uc.ac.kr/www/Main.do",
          badge: "동남권 전문대",
          univNo: 272,
          logo: require("@/assets/images/university/울산과학대학교.png"),
          info: "울산과학대학교는 성실, 창의, 봉사의 이념으로 민주시민의 자질을 갖춘 국가산업발전과 미래 사회 변화에 적응하는 글로벌 전문 지식을 갖춘 인재를 육성합니다.",
        },
        {
          id: 10,
          name: "거제대",
          fullname: "거제대학교",
          main: require(`@/assets/images/university/main/거제대학교.png`),
          manager: "허현미",
          callNumber: "055-680-1686",
          address: "경남 거제시 마전1길 91(장승포동 654-1)",
          link: "https://www.koje.ac.kr/www",
          badge: "동남권 전문대",
          univNo: 20,
          logo: require("@/assets/images/university/거제대학교.png"),
          info: "Stay Young, Get Valor, Bring prosperity. 젊음의 용기로 도전하여 번영을 쟁취하는 인재",
        },
        {
          id: 10,
          name: "경남도립거창대",
          fullname: "경남도립거창대학교",
          main: require(`@/assets/images/university/main/경남도립거창대학교.png`),
          manager: "박준현",
          callNumber: "055-254-2756",
          address: "상남도 거창군 거창읍 거창대학로72 본관1층 산학협력단/창업지원단",
          link: "https://www.gc.ac.kr/newgc/main/index.asp",
          badge: "동남권 전문대",
          univNo: 31,
          logo: require("@/assets/images/university/경남도립거창대.png"),
          info: "우리나라 최초의 공립대학으로 국가와 경남주력산업 발전에 필요한 성실하고, 창의적인 융합기술인을 양성하기 위해 경상남도에서 설립한 대학입니다.",
        },
        {
          id: 10,
          name: "경남정보대",
          fullname: "경남정보대학교",
          main: require(`@/assets/images/university/main/경남정보대학교.png`),
          manager: "심주연",
          callNumber: "051-320-1351",
          address:
            "부산광역시 사상구 주례로 45 경남정보대학교 건학50주년기념관(D) 6층 602호 산학협력단",
          link: "https://www.kit.ac.kr/",
          badge: "동남권 전문대",
          univNo: 33,
          logo: require("@/assets/images/university/경남정보대학교.png"),
          info: "우리 대학의 창업 교육 프로그램은 학생들의 창업 마인드 함양 및 도전의식을 고취하여 창업에 대한 긍정적인 인식 형성 및 창업 분위기를 확산합니다. 또한, 우리 대학은 미래 청년기업가 양성을 위해 창업 교육 프로그램 및 창업보육공간을 지원합니다.",
        },
        {
          id: 10,
          name: "마산대",
          fullname: "마산대학교",
          main: require(`@/assets/images/university/main/마산대학교.png`),
          manager: "정나겸",
          callNumber: "055-230-1408",
          address: "경남 창원시 마산회원구 내서읍 함마대로 2640 19호관 3층 취창업진로지원센터",
          link: "https://www.masan.ac.kr/kor/Main.do?sso=S",
          badge: "동남권 전문대",
          univNo: 150,
          logo: require("@/assets/images/university/마산대학교.png"),
          info: "우리 대학은 창의적이고 인류와 사회에 봉사하는 전문기술인을 양성합니다. 또한, 스스로 탐구하고 계획하고 실천하는 창조적인 인재를 양성하기 위해 노력하고 있습니다.",
        },
      ],
    },
  },
];
