import axios from "axios";
import {useState} from "react";
import {ES_TEST_CERTIFICATE} from "@/constants/common";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const ElasticSearchTest = () => {
  const [endPoint, setEndPoint] = useState("");
  const [baseurl, setBaseurl] = useState("");
  // 인증 state
  const [isCertificated, setIsCertificated] = useState(false);
  const [certificateInput, setCertificateInput] = useState("");
  const navigate = useNavigate();

  const submitCertification = (e) => {
    e.preventDefault();
    if (certificateInput !== ES_TEST_CERTIFICATE) return navigate("/");
    toast.info("귿귿~~");
    setIsCertificated(true);
  };

  const fetch = (e) => {
    (async () => {
      e.preventDefault();

      const instance = axios.create({
        baseURL: baseurl,
        method: "get",
        auth: {username: "elastic", password: "TPyH3750zgSCOaAAH5hY"},
        params: {
          size: 1000,
        },
      });

      instance
        .get(endPoint)
        .then((res) => console.log("테스트~~~~~~~", res.data))
        .catch((e) => console.log(e));
    })();
  };

  return (
    <>
      {isCertificated ? (
        <div className="myTestBox" style={{width: "500px"}}>
          <div style={{display: "flex", flexDirection: "column", gap: "40px"}}>
            {/* baseurl */}
            <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
              <p>Base URL 설정 인풋</p>
              <p>
                baseurl: <span style={{fontWeight: "300"}}>{baseurl}</span>
              </p>
              <div style={{display: "flex", gap: "8px"}}>
                <input type="text" value={baseurl} onChange={(e) => setBaseurl(e.target.value)} />
              </div>
            </div>

            {/* submit */}
            <form onSubmit={fetch} style={{display: "flex", flexDirection: "column", gap: "8px"}}>
              <p>GET End Point</p>
              <div style={{display: "flex", gap: "8px"}}>
                <input type="text" value={endPoint} onChange={(e) => setEndPoint(e.target.value)} />
                <button type="submit">submit</button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <form
          style={{
            backgroundColor: "black",
            display: "flex",
            padding: "30px",
            width: "fit-content",
            gap: "20px",
          }}
          onSubmit={submitCertification}
        >
          <p style={{color: "white"}}>암호를 대시오~~~</p>
          <input
            type="password"
            value={certificateInput}
            onChange={(e) => setCertificateInput(e.target.value)}
          />
        </form>
      )}
    </>
  );
};

export default ElasticSearchTest;
