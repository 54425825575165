import React from "react";
import EducationApply from "./EducationParticipation/EducationApply";
import EducationParticipation from "./EducationParticipation/EducationParticipation";
import EducationLearning from "./EducationParticipation/EducationLearning";
import EducationCompletion from "./EducationParticipation/EducationCompletion";
import EducationPlan from "./EducationParticipation/EducationPlan";

const MainEducation = () => {
  return (
    <section className="educationWrapper">
      <div className="educationTitleBox">
        <span className="educationBadge">SCOUT Education</span>
        <h2 className="educationTitle">스카우트 프로그램 참여</h2>
      </div>

      <EducationApply />
      <EducationParticipation />
      <EducationPlan />
      <EducationLearning />
    </section>
  );
};

export default MainEducation;
