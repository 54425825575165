// eslint-disable-next-line no-unused-vars
import React from "react";
import ProjectIcon1 from "@/assets/svg/projectSvg/project_icon1.svg";
import ProjectIcon2 from "@/assets/svg/projectSvg/project_icon2.svg";
import ProjectIcon3 from "@/assets/svg/projectSvg/project_icon3.svg";
import {Swiper, SwiperSlide} from "swiper/react";
// import Swiper core and required modules
import {Navigation, Pagination} from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const MainProject = () => {
  const projectInformation = [
    {
      id: 1,
      name: "공유 및 협력",
      title: (
        <>
          대학 중심
          <br /> 창업교육 허브 구축
        </>
      ),
      info: (
        <>
          창업교육 격차 해소
          <br /> 교류 확대 및 연계 강화
        </>
      ),
      icon: ProjectIcon1,
      colorClass: "blue",
      bgClass: "blue",
    },
    {
      id: 2,
      name: "교육 기반 구축",
      title: (
        <>
          지역 협업 창업교육
          <br /> 생태계 기반 구축
        </>
      ),
      info: (
        <>
          콘텐츠 공동 개발 및 보급 <br /> 교육모델 확산
        </>
      ),
      icon: ProjectIcon2,
      colorClass: "yellow",
      bgClass: "yellow",
    },
    {
      id: 3,
      name: "창업DB 구축",
      title: (
        <>
          창업교육
          <br /> 지원 체계 구축
        </>
      ),
      info: (
        <>
          수요자 중심 <br /> DB 체계 구축
        </>
      ),
      icon: ProjectIcon3,
      colorClass: "red",
      bgClass: "red",
    },
  ];

  const description = [
    {
      id: 1,
      title: "창업교육 프로그램",
      content: (
        <>
          실전 창업교육을 지원하는 <br />
          온오프라인 연계 <br /> 교육 프로그램 제공
        </>
      ),
    },
    {
      id: 2,
      title: <>비즈니스 모델 캔버스</>,
      content: (
        <>
          창업아이템 기반 사업 모델
          <br /> 검증 및 고도화 도구 지원
        </>
      ),
    },
    {
      id: 3,
      title: "멘토링",
      content: (
        <>
          실전 창업과정 중 <br /> 애로상황에 대한 <br />
          분야별 전문가의 피드백 제공
        </>
      ),
    },
    {
      id: 4,
      title: "지역문제해결허브",
      content: (
        <>
          지역별 문제 해결 사례 <br /> 공유 및 확산 지원
        </>
      ),
    },
    {
      id: 5,
      title: "창업교육 콘텐츠",
      content: (
        <>
          지역별 특성을 반영한 <br /> 콘텐츠 탑재 및 확산
        </>
      ),
    },

    {
      id: 6,
      title: "창업 DB",
      content: (
        <>
          창업 교육 참여 이력 등<br /> 이력 관리 지원
        </>
      ),
    },
  ];

  return (
    <section className="mainProjectContainer">
      <div className="mainProjectWrapper">
        <h3 data-aos="fade-up" data-aos-duration="1000">
          <span className="strong">SCOUT </span>NETWORK
        </h3>
        <p
          className="projectExplanation"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          대학을 중심으로 지역 협업체계를 강화하여 지역간 창업교육 격차를 해소하고, 수요자 중심의
          서비스를 제공하는 플랫폼입니다.
        </p>

        <div
          className="projectInfoList"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{
              type: "bullets",
            }}
            slidesPerView={1}
            spaceBetween={18} // 여기에 spaceBetween 속성 추가
            breakpoints={{
              801: {
                slidesPerView: 3,
              },
            }}
            scrollbar={{draggable: true}}
          >
            {projectInformation.map((item) => (
              <SwiperSlide key={item.id} className="projectInfoBox">
                <li className={`projectInfoItem ${item.bgClass}`} key={item.id}>
                  <p className={`projectInfoName ${item.colorClass}`}>{item.name}</p>
                  <div className="projectInfoIconBox">
                    <img className="projectInfoIcon" src={item.icon} alt={`${item.name}`} />
                    <p className="projectInfoTitle">{item.title}</p>
                    <p className="projectInfoContent">{item.info}</p>
                  </div>
                </li>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <ul className="projectContentsList">
          {description.map((item) => (
            <li
              className="projectContentsItem"
              key={item.id}
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="projectContentsTextBox">
                <p className="projectContentsTextTitle">{item.title}</p>
                <p className="projectContentsText">{item.content}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default MainProject;
