// lazy 로딩 적용법
import {lazy} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";

// 메인 페이지
import MainPage from "@/pages/MainPage";
// import EducationSchedule from "./pages/education/educationSchedule/EducationSchedule";
// ! test - 테스트 후 수정할 예정
import NotFound from "@/components/common/notFounds/NotFound";
import ElasticSearchTest from "@/pages/test/ElasticSearchTest";
import NavigateTransition from "@/components/common/navigateTransition/NavigateTransition";

// 권한 라우터
const AuthPrivateElement = lazy(() => import("@/components/private/AuthPrivateElement"));
// 준비중인 페이지
const Prepare = lazy(() => import("@/components/common/prepare/Prepare"));
// auth
const UserLogin = lazy(() => import("@/pages/auth/UserLogin"));
const UserSignUp = lazy(() => import("@/pages/auth/UserSignUp"));
const UserModify = lazy(() => import("@/pages/auth/UserModify"));
const FindUserInformation = lazy(() => import("@/pages/auth/findUserInfo/FindUserInformation"));
const UserMyPage = lazy(() => import("@/pages/auth/UserMyPage"));
// fnb
const Terms = lazy(() => import("@/pages/fnb/Terms"));
const Privacy = lazy(() => import("@/pages/fnb/Privacy"));
const FnbEmail = lazy(() => import("@/pages/fnb/FnbEmail"));
// about - 스카우트 , 컨소시엄 , 대학
const ScoutAbout = lazy(() => import("@/pages/about/ScoutAbout.jsx"));
const ConsortiumAbout = lazy(() => import("@/pages/about/ConsortiumAbout"));
const UniversityIntroduction = lazy(() =>
  import("@/pages/about/ConsortiumAbout/UniversityIntroduction"),
);
// education 창업교육 프로그램
// ! 신청하기 삭제
// const EducationApply = lazy(() =>
//   import("@/pages/education/educationCourse/educationApply/EducationApply.jsx"),
// );
// ! 신청확인 삭제
// const EducationCheck = lazy(() => import("@/pages/education/educationCheck/EducationCheck"));

const EducationParticipate = lazy(() =>
  import("@/pages/education/educationParticipate/EducationParticipate"),
);
const EducationLecture = lazy(() => import("@/pages/education/educationLecture/EducationLecture"));
const EditCourseLayout = lazy(() => import("@/pages/editCourse/layout/EditCourse.layout"));
const EditCourse = lazy(() => import("@/pages/editCourse/main/EditCourse"));
// const EducationListPage = lazy(() => import("@/pages/education/educationList/EducationListPage"));
const EducationListPage = lazy(() =>
  import("@/pages/education/educationListPage/EducationListPage"),
);
const EducationCourseInfo = lazy(() =>
  import("@/pages/education/educationCourse/EducationCourseInfo"),
);
// const TEST = lazy(() => import("@/pages/education/educationCourse/TEST"));
const EducationCourseManagement = lazy(() =>
  import("@/pages/education/educationCourse/educationCourseManagement/EducationCourseManagement"),
);

// 창업교육 툴킷 - 비즈니스 모델 캔버스
// 목록
const BmcAbout = lazy(() => import("@/pages/toolkit/bmc/BmcAbout"));
const BMCListPage = lazy(() => import("@/pages/toolkit/bmc/BMCListPage"));
const BMCWritePage = lazy(() => import("@/pages/toolkit/bmc/BMCWritePage"));
const BMCModifyPage = lazy(() => import("@/pages/toolkit/bmc/BMCModifyPage"));
const BMCConnectedListPage = lazy(() => import("@/pages/toolkit/bmc/BMCConnectedListPage"));
// 캔버스
const BMCModelCanvas = lazy(() => import("@/pages/toolkit/bmc/BMCModelCanvas"));
const BusinessPlan = lazy(() => import("@/pages/toolkit/BusinessPlan"));
const AICustomerValidation = lazy(() => import("@/pages/toolkit/AICustomerValidation"));

// 지역 문제해결 허브
const LocalMap = lazy(() => import("@/pages/local/LocalMap"));
// 게시판
const BoardLayout = lazy(() => import("@/pages/board/layout/BoardLayout"));
const BoardList = lazy(() => import("@/pages/board/main/commonBoard/CommonBoardList"));
const BoardDetails = lazy(() => import("@/pages/board/detail/BoardDetails"));
const BoardEdit = lazy(() => import("@/pages/board/edit/BoardEdit"));
const GalleryBoard = lazy(() => import("@/pages/board/main/galleryBoard/GalleryBoard"));

// 통합검색
const ElasticSearch = lazy(() => import("@/pages/elasticSearch/ElasticSearch"));

function MainRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<NavigateTransition />}>
          {/* 메인페이지 "/" */}
          <Route path="/" exact element={<MainPage />} />

          {/* 소개페이지 "/about" */}
          <Route path="/about">
            <Route path="scout" element={<ScoutAbout />} />
            <Route path="consortium">
              <Route index element={<ConsortiumAbout />} />
              <Route path=":univNo" element={<UniversityIntroduction />} />
            </Route>
          </Route>

          {/* 교육 - "/education" */}
          <Route path="/education">
            {/* index not found */}
            <Route index element={<NotFound />} />

            <Route
              path="participate/:c_member_no/:c_edu_no/:c_no/:c_project_no"
              element={<EducationParticipate />}
            />

            <Route
              path="lecture/:c_home/:c_edu_no/:c_edu_project_no/:c_member_no/:c_project_no/:c_team_no/:c_curi_no"
              element={<EducationLecture />}
            />

            {/* 글로벌 nav _ 1번 */}
            <Route path="course">
              {/* <= 제한적으로 보이게 변경해야됨 */}
              <Route index element={<EducationCourseInfo />} />
              {/* <Route path="apply" element={<EducationApply />} /> Title : 신청 하기 */}
              {/* <Route path="check" element={<EducationCheck />} /> Title : 신청 확인 */}
            </Route>

            {/* 교육 목록 */}
            <Route path="list" element={<AuthPrivateElement />}>
              <Route index element={<EducationListPage />} />
              {/* 교수자만 접근 가능하도록 라우팅 */}
              <Route element={<AuthPrivateElement isOnlyProfessor={true} />}>
                <Route path="management" element={<EducationCourseManagement />} />
                <Route path="editCourse/*" element={<EditCourseLayout />}>
                  <Route index element={<EditCourse />} />
                </Route>
              </Route>
            </Route>

            {/* 교육 커리큘럼 - 상세일정 */}
            <Route
              path="schedule/:c_member_no/:c_edu_no/:c_no/:c_project_no"
              element={<EducationParticipate />}
            />
          </Route>

          {/* 창업교육 툴킷 - "/toolkit/bmc" */}
          <Route path="/toolkit">
            {/* index not found */}
            <Route index element={<NotFound />} />

            {/* bmc 소개 페이지 */}
            <Route path="bmcAbout" element={<BmcAbout />} />

            <Route path="bmc" element={<AuthPrivateElement />}>
              {/* BMC 작성하기 페이지 */}
              <Route path="list">
                <Route index element={<BMCListPage />} />
                <Route
                  path="connected/:c_edu_project_no/:c_project_no"
                  element={<BMCConnectedListPage />}
                />
                <Route path="write" element={<BMCWritePage />} />
                <Route path="modify" element={<BMCModifyPage />} />
                <Route path="modify/:c_team_no/:c_project_no" element={<BMCModifyPage />} />
              </Route>

              {/* BMC 캔버스 페이지 */}
              <Route path="model-canvas">
                {/* index not found */}
                <Route index element={<NotFound />} />

                <Route path=":c_team_no/:c_project_no" element={<BMCModelCanvas />} />
              </Route>
            </Route>

            <Route path="ai-customer" element={<AICustomerValidation />} />
            <Route path="biz-plan" element={<BusinessPlan />} />
          </Route>

          {/* 로컬실록 지리지 */}
          <Route path="/local/map" element={<LocalMap />} />

          {/* 게시판 (멘토링 - 공지사항 - 고객지원) */}
          <Route path="/board" element={<BoardLayout />}>
            {/*
                레이아웃 방식으로 index element 가 별도로 필요없지만, /board 경로를 들어가면 빈페이지를
                반환하는 것을 막기 위해 index element 를 NotFound 페이지로 지정하여 돌려보냄
              */}
            <Route index element={<NotFound />} />

            {/* 공지사항, 멘토링, 문의 */}
            <Route path=":boardCategory">
              {/* 게시글 리스트 */}
              <Route index element={<BoardList />} />
              {/* 상세보기 */}
              <Route path=":boardId/:boardNo" element={<BoardDetails />} />
            </Route>

            {/* 게시판 작성 (현재 멘토링만 가용중) */}
            <Route path="create">
              {/* index not found */}
              <Route index element={<NotFound />} />
              {/* 현재 게시판 작성은 멘토링만 존재 */}
              <Route path=":createCategory" element={<BoardEdit />} />
            </Route>

            {/* 창업교육 컨텐츠 (회원 전용 게시판) */}
            <Route path="eduContents">
              {/* index not found */}
              <Route index element={<NotFound />} />
              {/* 연구자료 = commonBoard, 나머지 = galleryBoard */}
              <Route path="researchRef">
                <Route index element={<BoardList />} />
                <Route path=":boardId/:boardNo" element={<BoardDetails />} />
              </Route>

              <Route path=":eduContentsCategory">
                <Route index element={<GalleryBoard />} />
                <Route path=":boardId/:boardNo" element={<BoardDetails />} />
              </Route>
            </Route>
          </Route>

          {/* 통합 검색 */}
          <Route path="/elastic" index element={<ElasticSearch />} />
          {/* <Route path="/elastic" index element={<Prepare />} /> */}

          {/* user */}
          <Route path="/auth">
            <Route path="login" element={<UserLogin />} />
            <Route path="signup" element={<UserSignUp />} />
            <Route path="modify" element={<UserModify />} />
            <Route path="findUserInfo" element={<FindUserInformation />} />

            {/* 마이페이지 */}
            <Route element={<AuthPrivateElement></AuthPrivateElement>}>
              <Route path="mypage" element={<UserMyPage />} />
            </Route>
          </Route>

          {/* fnb */}
          <Route path="/fnb">
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="email" element={<FnbEmail />} />
          </Route>

          {/* ! 준비중인 페이지를 위해서 넣어놨습니다. -하연  */}
          <Route path="/prepare" element={<Prepare />} />

          {/* todo: 테스트 이후 삭제 예정 */}
          <Route path="/esTest" element={<ElasticSearchTest />} />

          {/* Not Found */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default MainRoutes;
