export const main_search_mock = [
  {
    id: 1,
    title: "사업소개",
    params: "/about/scout",
  },
  {
    id: 2,
    title: "프로그램 소개",
    params: "/education/course",
  },
  {
    id: 3,
    title: "BMC",
    params: "/toolkit/bmc/list",
  },
];
