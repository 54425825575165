import "./Footer.css";

import {Link} from "react-router-dom";
import footerImg1 from "@/assets/images/footer/footerImg1.png";
import footerImg2 from "@/assets/images/footer/footerImg2.png";
import footerImg3 from "@/assets/images/footer/footerImg3.png";
import useResponsive from "@/hooks/useResponsive";

const footerLogo = [
  {alt: "교육부 로고", img: footerImg1, href: "https://www.moe.go.kr/main.do?s=moe"},
  {
    alt: "KOEF 한국청년기업가정신재단 로고",
    img: footerImg2,
    href: "https://www.nrf.re.kr/index",
  },
  {
    alt: "한국 연구 재단",
    img: footerImg3,
    href: "http://www.koef.or.kr",
  },
];

const FooterComponent = () => {
  const {isMobile} = useResponsive();

  /* 푸터 메뉴 컴포넌트 (정보 처리 방침) */
  function InfoLinks() {
    return (
      <ul className="fnb">
        {[
          {title: "개인정보 처리방침", to: "fnb/privacy"},
          {title: "서비스 이용약관", to: "fnb/terms"},
          {title: "이메일 무단 수집 거부", to: "fnb/email"},
        ].map((item, idx, arr) => (
          <li className="fnb" key={idx}>
            <div>
              <Link to={item.to} className="infoLink">
                {arr.length - 1 === idx ? item.title : item.title}
              </Link>
            </div>
            {arr.length - 1 !== idx && !isMobile && <p style={{color: "#8f8f8f"}}>|</p>}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div id="footer">
      <div className="footer_inner">
        <div className="content">
          <div className="footerLogos">
            {/* 푸터 메뉴 (정보 처리 방침) */}
            {!isMobile && <InfoLinks />}

            {/* 운영, 지원 사업단 로고 */}
            <ul className="site_link">
              {footerLogo.map((info, idx) => (
                <li key={idx}>
                  <a
                    className={"footer_logo_" + (idx + 1)}
                    href={info.href}
                    title={info.alt}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <img src={info.img} alt={info.alt} />
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="footerContentInner">
            {/* 사업단 마크 */}
            <img
              className="footer_logo"
              src={require("@/assets/images/footer_logo.png")}
              alt="footer logo"
            />

            {/* 사업단 정보 */}
            <div className="addressContainer">
              {/* 모바일인 경우 display: none */}
              {!isMobile && (
                <div>
                  <p className="footerScoutTitle">창업교육 혁신 선도전문대학(SCOUT)사업협의회</p>
                  <p>STARTUP Co-Op University for Transition of edu hub district</p>
                </div>
              )}

              {/* 주소 */}
              <ul className="address_content">
                <li>
                  주소: 서울특별시 서초구 서초대로 45길 16, 202
                  {isMobile && <br />}
                  <span style={{paddingLeft: isMobile ? "30px" : "0"}}>(서초동, 브이알빌딩)</span>
                </li>
                <li>운영기관: 한국청년기업가정신재단</li>
                <li>지원: 교육부, 한국연구재단</li>
              </ul>

              {/* 모바일인 경우 display: none */}
              {!isMobile && (
                <p>COPYRIGHT © BY KOREA ENTREPRENEURSHIP FOUNDATION. ALL RIGHTS RESERVED.</p>
              )}
            </div>

            {/* 처리방침 등 메뉴 모바일에선 하단 렌더링 */}
            {isMobile && <InfoLinks />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
