import React, {useRef, useState} from "react";
import SearchIcon from "@/assets/svg/search.svg";
import {useEffect} from "react";

const MainSearchInput = ({parentsubmit, isSearchVisible}) => {
  const [inputValue, setInputValue] = useState("");
  const searchInput = useRef();
  // 입력값 변경 핸들러

  useEffect(() => {
    searchInput.current.focus();
  }, [isSearchVisible]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // 부모에서 검색 api 호출
    parentsubmit(inputValue);
    // 값 비워주기
    setInputValue("");
  };

  return (
    <div className="searchInputContainer">
      <form onSubmit={handleSubmit}>
        <div className="searchFormBox">
          <label htmlFor="searchInput" className="hidden">
            검색
          </label>

          <input
            className="searchInput"
            type="text"
            ref={searchInput}
            id="searchInput"
            name="searchInput"
            placeholder="검색어 입력"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button className="searchButton" type="submit">
            <img src={SearchIcon} alt="SearchIcon" />
          </button>
        </div>
      </form>
    </div>
  );
};

MainSearchInput.defaultProps = {
  parentsubmit: (e) => {
    e.preventDefault();
    console.log("폼 제출");
  },
};

export default MainSearchInput;
