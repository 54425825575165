import {configureStore} from "@reduxjs/toolkit";
import exemple from "./modules/lms.js";
import auth from "./modules/authSlice.js";
import todo from "./modules/Todo.js";
import customer from "./modules/customerSlice.js";
import dateSlice from "./modules/dateSlice.js";
import editCourseSlice from "./modules/editCourse.js";
import educationReducer from "./modules/educationSlice.js";
// store 연결해줘야됨
export default configureStore({
  reducer: {
    // 변수작명: user.reducer,
    customer: customer.reducer,
    auth: auth.reducer,
    exemple: exemple.reducer,
    todo: todo.reducer,
    dateSlice: dateSlice.reducer,
    editCourseSlice: editCourseSlice.reducer,
    education: educationReducer,
  },
  // devTools: process.env.NODE_ENV !== "production",
  devTools: true,
});
