import {useMemo} from "react";

const useHoverAnimation = () => {
  // 애니메이션 설정
  const animation = useMemo(
    () => ({
      whileHover: {y: -14},
      transition: {
        type: "spring",
        damping: 10,
        mass: 1,
        stiffness: 100,
        ease: "easeInOut",
        duration: 0.3,
      },
    }),
    [],
  );

  return animation;
};

export default useHoverAnimation;

// 사용 예시
// EducationBlock.jsx;
