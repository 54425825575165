// 로그인 , 회원가입
import {auth, guestAuth} from "./index";

// 로그인 없이 사용가능한 api ( guestAuth )
/**
 * @description 로그인
 *  @param  {Object} userData
 * @property {String} email - 아이디 "test115@test.net"
 * @property {String} password - 비밀번호  "thfel123"
 * @return Token
 */
export function loginUser(userData) {
  return guestAuth.post("/auth/login", userData);
}

/**
 * @description 이메일 인증번호 보내기
 * @param  {Object} userData
 * @property {String} email - 유저 (id) 이메일
 */
export function sendMailAuth(userData) {
  return guestAuth.post("/auth/mailsend", userData);
}

/**
 * @description 이메일 인증확인
 * @param  {Object} userData
 * @property {String} email - 유저 (id) 이메일
 * @property {String} number - 이메일 확인번호
 */
export function checkMailAuth(userData) {
  return guestAuth.post("/mailauthcheck", userData);
}

/**
 * @description 이메일 중복확인
 * @param {String} email - 유저 (id) 이메일
 */
export function checkEmailDuplication(email) {
  return guestAuth.post("/auth/checkemail", email);
}

/**
 * @description 휴대폰 인증번호 발송
 * @param {String} phone - 휴대폰번호
 */
export function sendPhoneVerificationCode(phone) {
  return guestAuth.post("/auth/phonesend", phone);
}

/**
 * @description 휴대폰 인증번호 확인
 * @param {String} phone - 휴대폰번호
 */
export function checkPhoneVerificationCode(phone) {
  return guestAuth.post("/auth/phoneauthcheck", phone);
}

/**
 * @description 휴대폰으로 비밀번호 발송 - 비밀번호 찾기
 * @param {{ phone: string }} phone - 휴대폰번호
 */
export function sendPhoneNewPassword(phone) {
  return guestAuth.post("/auth/passwordsnssend", phone);
}

/**
 * @description 휴대폰으로 아이디 발송 - 아이디 찾기
 * @param {{ phone: string }} phone - 휴대폰번호
 */
export function sendPhoneUserEmail(phone) {
  return guestAuth.post("/auth/emailsnssend", phone);
}

// /**
// ! 기존 회원가입에서 파일 업로드 하는 방식으로 변경됨
//  * @description 유저 회원가입
//  * @param  {Object} userData
//  * @property {String} id - 유저 (id) 이메일
//  * @property {String} pass - 비밀번호
//  * @property {String} gubun - 유저 타입 (학생,교육자,기타)
//  * @property {String} name - 유저 이름
//  * @property {String} phone - 휴대폰
//  * @property {String} univNo - 대학교  id
//  * @property {String} sex - 성별
//  * @property {String} birth - 생년월일 ( yyyy-mm-dd )
//  * @property {String} region - 사는 지역 ( 시, 도 )
//  */
// export function insertMember(userData) {
//   return guestAuth.post("/auth/member/new", userData);
// }

/**
 * @description 유저 회원가입
 * @param  {Object} certification - 교수자 증명서 파일
 * @param  {Object} nweMemberReqVo - json 객체
 * @property {String} id - 유저 (id) 이메일
 * @property {String} pass - 비밀번호
 * @property {String} gubun - 유저 타입 (학생,교육자,기타)
 * @property {String} name - 유저 이름
 * @property {String} phone - 휴대폰
 * @property {String} univNo - 대학교  id
 * @property {String} sex - 성별
 * @property {String} birth - 생년월일 ( yyyy-mm-dd )
 * @property {String} region - 사는 지역 ( 시, 도 )
 */

export function insertMember(requestData) {
  // * charset 추가
  return guestAuth.post("/auth/member/new", requestData, {
    headers: {"Content-Type": "multipart/form-data; charset: UTF-8;"},
  });
}

/**
 * @description 유저 토큰 유효성 검사
 * @param {String} phone - 모바일 번호
 */
export function sendPhoneTempPassword(requestData) {
  return auth.post("/passwordsnssend", requestData);
}

// ****** 로그인 한 유저가 사용하는 api ( auth ) *****
/**
 * @description 유저 토큰 유효성 검사
 *  @param  {Object} userData
 * @property {String} accessToken - 토큰
 * @property {String} refreshToken - 리프래쉬 토큰
 */
export function checkVerifyToken(userData) {
  return auth.post("/verify", userData);
}

/**
 * @description 로그아웃 - 토큰 받은 후
 * @param  {Object} userData
 * @property {Number} cNo - 유저 id ( 6783 )
 * @property {String} cName - 유저 이름 ( 가나카 )
 * @return Token
 */
export function logoutUser(userData) {
  return auth.post("/logout", userData);
}

/**
 * @description 정보수정 페이지 - 학과 변경
 * @param  {Object} userData
 * @property { Number } memberNo - 유저 id
 * @property {String} major - 변경할 학과 이름
 * @return Token
 */
export function updateUserMajor(userData) {
  return auth.post("/setmajor", userData);
}

/**
 * @description 정보수정 페이지 - 학번 변경
 * @param  {Object} userData
 * @property { Number } memberNo - 유저 id
 * @property {String} studentId - 변경할 학번 이름
 * @return Token
 */
export function updateUserStudentid(userData) {
  return auth.post("/setstudentid", userData);
}
