/**
 * 컴포넌트 다발적 사용으로 인한 참조 순환을 방지하기 위해
 * index.js에서 import해주어 참조 순환 방지
 * */

import {useCallback, useEffect, useRef} from "react";
import ModalHedaer from "./ModalHedaer";
import {motion} from "framer-motion";

/**
 * @desc 스타일 지정하여 사용 가능한 모달 컴포넌트이며 width, height 를 반드시 지정해주어야 함
 * @param {{ setShowModal: any, children: any, modalStyle: CSSProperties }}
 *  */
// setShowModal -> EducationBlock에서 전달
export default function Modal({
  children,
  setShowModal,
  modalStyle = {},
  modalTitle,
  onClose,
  closeClickHandler,
}) {
  const overlay = useRef(null);

  // 모달 닫기
  const onDismiss = useCallback(() => {
    setShowModal(false); // modal 꺼주기
    if (onClose) onClose();
  }, [setShowModal]);

  // 클릭 모달 닫기
  const onClick = useCallback(
    (e) => {
      if (e.target !== overlay.current) return;
      onDismiss();
    },
    [onDismiss, overlay],
  );

  // ESC 키다운 이벤트
  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") onDismiss();
    },
    [onDismiss],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  return (
    <motion.div
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      className="OverlaySection"
      ref={overlay}
      onClick={onClick}
    >
      <div className="modal_wrapper" style={modalStyle}>
        <ModalHedaer
          modalTitle={modalTitle}
          setShowModal={setShowModal}
          closeClickHandler={closeClickHandler}
        />
        <div className="modal_content">{children}</div>
      </div>
    </motion.div>
  );
}
