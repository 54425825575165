import React, {useEffect, useRef, useState} from "react";
import useHoverAnimation from "@/hooks/useHoverAnimation";
import {motion} from "framer-motion";

import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Navigation, Autoplay } from "swiper/modules";

import "@/pages/about/ConsortiumAbout.css";

import {useNavigate} from "react-router-dom";
import {consortiumData} from "@/mock/consortium-mock";
import MainPageButton from "./MainPageButtons";

const MainConsortium = () => {
  const navigate = useNavigate();
  const animation = useHoverAnimation();
  const [swiper, setSwiper] = useState(0); // -> 슬라이드용
  const [currentSlider, setCurrentSlider] = useState(1); 

  const handlePrev = () => {
    swiper?.slidePrev();
  };
  const handleNext = () => {
    swiper?.slideNext();
  };

  // 대학을 선택할 때 호출되는 함수
  const handleUniversityClick = (university) => {
    navigate(`/about/consortium/${university.univNo}`, {
      state: {selectedUniversity: university},
    });
  };

  const allUniversities = consortiumData.flatMap((consortium) => [
    ...Object.values(consortium.universities.general),
    ...Object.values(consortium.universities.vocational),
  ]);

  console.log(allUniversities);

  const universitiesById = allUniversities.reduce((acc, item) => {
    const id = item.id; // 각 대학의 id
    if (!acc[id]) {
      acc[id] = []; // id에 해당하는 배열 초기화
    }
    acc[id].push(item); // 해당 id에 대학 추가

    console.log(id, "idzz")
    return acc;
  }, {});

  console.log(universitiesById, "?");

  const handleSlideChange = (swiper) => {
    setCurrentSlider(swiper.realIndex + 1); // Swiper의 realIndex는 0부터 시작하므로 +1
};

  return (
    <div className="mainConsortiimContainer">
    <div className="sectionTitle">

        <div className="consortium_btn_box">
          <div className="slideButtonPagenation">
            <span className="slidePrevNumber currentNumber">{currentSlider}</span>
            <span className="slidePageSlash">{"/"}</span>
            <span className="slideNextNumber">{Object.keys(universitiesById).length}</span>
          </div>

          <button onClick={handlePrev}>
            이전
            <MainPageButton color="#eee" hoverColor="#024C8C" position="left" />
          </button>
          <button onClick={handleNext}>
            다음
            <MainPageButton color="#eee" hoverColor="#024C8C" position="right" />
          </button>
        </div>

         

  <div className='main_university_section'>
    <Swiper
      modules={[Navigation, Autoplay]} // Autoplay 모듈 추가
      loop={true}
      autoplay={{delay: 3500, disableOnInteraction: false}} // 오토플레이 설정
      speed={1000} // 슬라이드 전환 속도 설정
      onSlideChange={handleSlideChange} // 슬라이드 변경 이벤트 핸들러 추가
      spaceBetween={50}
      onSwiper={(e) => {
        setSwiper(e);
      }}
    >
      {Object.entries(universitiesById).map(([id, universities]) => {
        // id를 2로 나누고 내림하여 인덱스를 계산
        const index = Math.floor((id - 1) / 2);
      const isOdd = parseInt(id) % 2 !== 0;
      const universityType = isOdd ? "일반대" : "전문대";
      const badgeClass = isOdd ? "general_badge" : "vocational_badge"
      const role = isOdd ? consortiumData[index]?.general_role : consortiumData[index]?.vocational_role;
      const region = consortiumData[index]?.map;

    return (
      <SwiperSlide key={id}>
        <div className="section_title_header">
          <span className="introduce">권역별 컨소시엄소개</span>
          <div className="section_title_info">
            <h2 className="title">{region} 사업단</h2>
            <div className="description">
            {role}
            </div>
          </div>
      </div>

        <p className={badgeClass}>{universityType}</p>
        <ul className="main_consortium_card">
          {universities.map((item, idx) => (
            <motion.li
              className="vocational_list"
              {...animation}
              key={idx}
              onClick={() => handleUniversityClick(item)}
            >
              <img src={item.logo} alt={`${item.fullname} 로고`} />
              <div className="name">
                <p>{item.host && <span className="general">주관대학</span>}</p>
                {item.fullname}
              </div>
            </motion.li>
          ))}
        </ul>
      </SwiperSlide>
    );
  })}
    </Swiper>
    </div>
    </div>
    </div>
  );
};

export default MainConsortium;
