import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import image from "@/assets/svg/main_img_01.svg";

const EducationApply = () => {
  const navigate = useNavigate();
  const handleShorcut = () => navigate("/education/course");

  return (
    <div className="educatioInfoContainer">
      <div className="educationContentsBox">
        <img
          className="educationInfoImage"
          src={image}
          data-aos="fade-up"
          data-aos-duration="1000"
        />
        <div
          className="educationInfoBox"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <span className="number">01</span>
          <h3 className="title">프로그램 신청</h3>
          <p className="description">
            원하는 창업교육을 선택해서 신청을 할 수 있습니다.
            <br />
            상세일정 등 정보를 확인하여 신청이 가능합니다.
          </p>
          <span className="shorcutButton" onClick={handleShorcut}>
            바로가기
            <button>
              <img src={require("@/assets/images/icon_arrow_right_blue.png")} />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default EducationApply;
