import "./Header.css";
import React, {useEffect, useState} from "react";
// lib
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
// components
import MainSearch from "@/pages/main/MainSearch";
import SearchIcon from "@/assets/svg/search.svg";
// redux
import {logout} from "@/store/modules/authSlice";
// api
import {logoutUser} from "@/api/auth";
// hooks
import useResponsive from "@/hooks/useResponsive.jsx";
// utils
import {getTokenCookies} from "@/utils/cookies";
// constans
import {HEADER_LIST} from "@/constants/header";
import HeaderAuth from "./HeaderAuth";
import {ROLE_MSG} from "@/constants/common";
import HeaderListItem from "./HeaderListItem";
import {useRef} from "react";
import MainQuickMenu from "@/pages/main/MainQuickMenu";
import QuickMenu from "@/components/common/quickMenu/QuickMenu";

// fixme: constants 내부에 고정값 배열 만들어서 map으로 구현 및 필요없는 태그와 스타일 제거
const HeaderComponent = () => {
  const {accessToken, userInfo} = getTokenCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // 모바일 메뉴
  const [isSearchVisible, setIsSearchVisible] = useState(false); // 검색창 오픈
  const searchInput = useRef(null);

  // * 퀵메뉴 애니메이션 커스텀으로 만들기
  // const [isAniInterval ,setIsAniInterval ] = useState(false)

  // mobile accodian
  const {isMobile, isTablet} = useResponsive();
  const [accordion, setAccordion] = useState([]); //accrdian boolean 배열

  // token이 있으면 로그인 상태
  useEffect(() => {
    if (accessToken) return setIsLogin(true);
    setIsLogin(false);
  }, [accessToken]);

  // 초기 모바일 accodian 토글 배열 기본값 false 지정
  // 추후 useRoutes를 사용한 length로 변경
  useEffect(() => {
    const N = HEADER_LIST.length; //gnb 갯수만큼 토글 배열설정
    const accodianArray = new Array(N).fill(false);
    setAccordion(accodianArray);
  }, []);

  // 로그아웃 클릭
  const handleLogout = async (e) => {
    e.preventDefault();
    // console.log("로그아웃 클릭");

    try {
      const requestData = {
        cNo: userInfo.cNo,
        cName: userInfo.cName,
      };
      // eslint-disable-next-line
      const {data} = await logoutUser(requestData);
      // console.log("로그아웃 성공", data);
      dispatch(logout());
      // * navigate는 logout함수에 있기에 주석처리함 - 하연님이 시킴
      // navigate("/", {replace: true});
    } catch (error) {
      console.log("로그아웃 에러", error);
    }
  };

  // 검색창 오픈
  const handleToggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  // 모바일에서 nav 아코디언
  const handleNavAccodianToggle = (index) => {
    if (!isMobile && !isTablet) return;
    const tempArray = accordion.map((item, idx) => (idx === index ? !item : false));
    setAccordion(tempArray);
  };

  // gnb 클릭 - (pc와 모바일 + 테블릿의 호버이벤트, 클릭이벤트를 다르게 함)
  const handleGnb = (e, item, index) => {
    e.preventDefault();
    if (!isMobile && !isTablet) {
      // pc
      navigate(item.to);
    } else {
      // mobile
      handleNavAccodianToggle(index);
    }
  };

  // 서브 메뉴 클릭
  const handleSub = (e, item) => {
    e.preventDefault();
    // 문의 접근 권한 확인
    if (!userInfo.cNo && item.title === "1:1문의") return toast.warn(ROLE_MSG);
    navigate(item.to);
    setIsMenuOpen(false);
  };

  return (
    <header id="header" className={isMobile || isTablet ? "mobile" : "pc"}>
      <div className="header_inner">
        <h1 className="header_logo">
          <Link to="/">창업교육 혁신 선도대학 SCOUT</Link>
        </h1>

        <nav id="gnb" className={isMenuOpen ? "on" : ""}>
          <h2 className="hidden">Navgation Area</h2>
          <div className="btn_gnb_open" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <span>모바일 메뉴열기</span>
          </div>
          <div className="modal_bg"></div>

          <div className="menu_box_wrapper">
            <ul className="menu_box">
              {HEADER_LIST.map((headerItem, idx) => (
                // gnb snb 네비게이션
                <HeaderListItem
                  key={headerItem.title}
                  headerItem={headerItem}
                  idx={idx}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  accordion={accordion}
                  handleSub={handleSub} //gnb 클릭
                  handleGnb={handleGnb} //snb 클릭
                ></HeaderListItem>
              ))}
            </ul>

            {/* 모바일 시 로그인,회원가입  */}
            {(isMobile || isTablet) && isMenuOpen && (
              <HeaderAuth
                isMobile={isMobile}
                isTablet={isTablet}
                isLogin={isLogin}
                handleLogout={handleLogout}
                setIsMenuOpen={setIsMenuOpen}
              ></HeaderAuth>
            )}
          </div>
        </nav>

        <div className={"search_auth_box"}>
          <button aria-label="검색" className="seach_button" onClick={handleToggleSearch}>
            검색하기
            <img src={SearchIcon} alt="SearchIcon" />
          </button>

          {isLogin && <p className="userinfo_name">{userInfo.cName} 님</p>}
          <HeaderAuth
            isLogin={isLogin}
            handleLogout={handleLogout}
            setIsMenuOpen={setIsMenuOpen}
          ></HeaderAuth>
        </div>
      </div>
      <MainSearch isSearchVisible={isSearchVisible} setIsSearchVisible={setIsSearchVisible} />
      <QuickMenu />
    </header>
  );
};

export default React.memo(HeaderComponent);
